import { useState, useCallback } from 'react';
import { Button, styled, keyframes, type ButtonProps } from '@mui/material';
import { Bolt, AutoAwesome } from '@mui/icons-material';
import { extendedTheme } from '../../../themes/extendedTheme';

const shimmer = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const particleExplosion = keyframes`
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(var(--dx), var(--dy)) scale(0);
  }
`;

const Particle = styled('span')<{ color: string }>(({ color }) => ({
  position: 'absolute',
  width: '8px',
  height: '8px',
  backgroundColor: color,
  borderRadius: '50%',
  animation: `${particleExplosion} 0.8s ease-out forwards`,
  pointerEvents: 'none',
}));

const MagicButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  background: `linear-gradient(-45deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main}, ${theme.palette.secondary.light})`,
  backgroundSize: '400% 400%',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: `0 0 24px ${theme.palette.primary.main}`,
  },
  '&.Mui-disabled': {
    background: theme.palette.action.disabledBackground,
  },
  '&.MuiButton-contained': {
    '&:active': {
      transform: 'scale(0.95)',
    },
  },
})) as typeof Button;

interface AIToggleButtonProps extends ButtonProps {
  buttonText: string;
  aiactive?: boolean;
}

export const AIToggleButton = ({
  aiactive = false,
  buttonText,
  ...props
}: AIToggleButtonProps) => {
  const [particles, setParticles] = useState<JSX.Element[]>([]);

  const primaryColor = extendedTheme.colorSchemes.light!.palette.primary.main;
  const generateParticles = useCallback(() => {
    const particleCount = 12;
    const newParticles = Array.from({ length: particleCount }).map((_, i) => {
      const angle = (i * 360) / particleCount;
      const distance = 100;
      return {
        dx: Math.cos((angle * Math.PI) / 180) * distance,
        dy: Math.sin((angle * Math.PI) / 180) * distance,
        color: i % 2 === 0 ? '#4bf2f7' : '#b8f4ff',
      };
    });

    const particles = newParticles.map(({ dx, dy, color }, index) => (
      <Particle
        key={index}
        color={color}
        style={{
          // CSS variables for animation parameters
          ['--dx' as any]: `${dx}px`,
          ['--dy' as any]: `${dy}px`,
        }}
      />
    ));

    setParticles(particles);
    setTimeout(() => setParticles([]), 800);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!props.disabled) {
      generateParticles();

      props.onClick?.(event);
    }
  };

  return (
    <MagicButton
      variant="contained"
      {...props}
      onClick={handleClick}
      startIcon={<AutoAwesome />}
      sx={{
        animation: `${shimmer} 8s ease infinite`,
        boxShadow: `0 0 24px 8px rgba(0, 111, 215, 0.5)`,
        ...props.sx,
      }}
    >
      {particles}
      {buttonText}
    </MagicButton>
  );
};
