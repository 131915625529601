import Box from '@mui/material/Box';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import { extendedTheme } from '../../../themes/extendedTheme';
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import logo from '../../../assets/giuribot_logo_extractor.png';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useNavigation } from 'react-router-dom';

const drawerWidth = 240;
function AppAppBar(props: { window?: Window; hideNavigation: boolean }) {
  const { t } = useTranslation();

  let homeTop = 0;
  let featuresTop = 0;
  let pricingTop = 0;
  let faqTop = 0;
  let faqHeight = 0;

  const navItems = ['home', 'features', 'pricing', 'FAQ'];

  const [mobileOpen, setMobileOpen] = useState(false);
  const [indexSelected, setIndexSelected] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const scrollToSection = (sectionId: string) => {
    const elementRef = props.window!.document.getElementById(sectionId)!;

    window!.scrollTo({
      top: elementRef.offsetTop + 1,
      behavior: 'smooth',
    });
  };

  const checkSelectionDisplay = (title: string) => {
    var index = navItems.indexOf(title);

    if (index == indexSelected) {
      return true;
    }

    return false;
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handleLogin = () => {
    navigate('/login');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}></Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              onClick={() => scrollToSection(item)}
            >
              <ListItemText primary={t(item)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const handleScroll = () => {
    const position = window!.scrollY;
    setScrollPosition(position);

    if (position <= featuresTop) {
      setIndexSelected(0);
    } else if (position <= pricingTop) {
      setIndexSelected(1);
    } else if (position <= faqTop) {
      setIndexSelected(2);
    } else if (position <= faqTop + faqHeight) {
      setIndexSelected(3);
    }
  };

  useEffect(() => {
    if (props.window == null || props.window == undefined) {
      return;
    }
    props.window!.addEventListener('scroll', handleScroll, { passive: true });

    homeTop = props.window.document.getElementById('home')!.offsetTop!;
    featuresTop = props.window.document.getElementById('features')!.offsetTop!;
    pricingTop = props.window.document.getElementById('pricing')!.offsetTop!;
    faqTop = props.window.document.getElementById('FAQ')!.offsetTop!;
    faqHeight = props.window.document.getElementById('FAQ')!.clientHeight!;

    return () => {
      window!.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const primaryColor = extendedTheme.colorSchemes.light!.palette.primary.main;
  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: 'rgba(256, 256, 256, 0.9)',
          border: 1,
          borderLeft: 0,
          borderRight: 0,
          backdropFilter: 'blur(5px)',
          borderColor: (theme) =>
            extendedTheme.colorSchemes.light!!.palette.background.default,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, ml: 0.5, display: { xs: 'block', sm: 'none' } }}
          >
            <MenuIcon
              sx={{
                color: 'black',
              }}
            />
          </IconButton>

          <Link href={'/'}>
            <Box
              component={'img'}
              width={'150px'}
              marginLeft={3}
              src={logo}
              display={{
                xs: 'none',
                sm: 'block',
                md: 'block',
              }}
            />
          </Link>

          {!props.hideNavigation ? (
            <Box
              marginLeft={6}
              sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
            >
              {navItems.map((item) => (
                <Button
                  key={item}
                  sx={{
                    fontSize: '16px',
                    marginLeft: '4px',
                    marginRight: '4px',
                    color: `${
                      checkSelectionDisplay(item) ? { primaryColor } : 'black'
                    }`,
                    textTransform: 'capitalize',
                    fontWeight: checkSelectionDisplay(item) ? 600 : 500,
                  }}
                  onClick={() => scrollToSection(item)}
                >
                  {t(item)}
                </Button>
              ))}
            </Box>
          ) : null}
          <Box
            display={'flex'}
            sx={{
              justifySelf: 'end',
            }}
            marginRight={3}
          >
            <Button
              disableElevation
              variant="text"
              style={{ color: 'primary' }}
              onClick={handleLogin}
              sx={{
                marginLeft: '10px',
                marginRight: '10px',
                textTransform: 'initial',
                fontWeight: 500,
                fontSize: '16px',
              }}
            >
              {t('login')}
            </Button>
            <Button
              onClick={handleRegister}
              disableElevation
              variant="contained"
              style={{ color: 'white' }}
              sx={{
                textTransform: 'capitalize',
                fontSize: '16px',
                backgroundColor: primaryColor,
              }}
            >
              {t('sign_up')}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
}

export default AppAppBar;
