import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState } from 'recoil';
import { toastAtom } from '../../state/toast.atom';

export default function CustomToast() {
  const [toastState, setToastState] = useRecoilState(toastAtom);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastState((state) => ({
      ...state,
      open: false,
    }));
  };

  const action = (
    <React.Fragment>
      <Button color="inherit" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      {...toastState}
      open={toastState.open}
      autoHideDuration={toastState.autoHideDuration}
      onClose={handleClose}
      message={toastState.message}
      action={action}
    />
  );
}
