import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { toastAtom } from '../../state/toast.atom';
import { useRecoilState } from 'recoil';
import { appAtom } from '../../state/app.atom';
import { ValueModel } from '../../models/value.model';
import { fieldValidator } from '../../models/validation.model';
import { userValidationFields } from '../../models/user.model';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import {
  googleAuthApi,
  loginWithFirebaseEmailAndPasswordApi,
} from '../../api/users';
import GoogleButton from 'react-google-button';
import { app } from '../../services/firebaseSetUp';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthCredential,
  signInWithPopup,
} from 'firebase/auth';

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const Login = () => {
  const [toastState, setToastState] = useRecoilState(toastAtom);
  const [appState, setAppState] = useRecoilState(appAtom);

  const [isValid, setIsValid] = React.useState(false);

  const [email, setEmail] = React.useState<ValueModel<string>>({
    valid: false,
    value: '',
    error: '',
  });

  const [password, setPassword] = React.useState<ValueModel<string>>({
    valid: false,
    value: '',
    error: '',
  });

  React.useEffect(() => {
    if (email.valid && password.valid) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [email, password]);

  const navigate = useNavigate();

  const login = () => {
    setAppState((state) => ({
      ...state,
      isLoading: true,
    }));

    loginWithFirebaseEmailAndPasswordApi(email.value, password.value)
      .then(() => {
        setAppState((state) => ({
          ...state,
          isLoading: false,
        }));
        setToastState({
          open: true,
          message: 'Autenticazione avvenuta con successo',
          autoHideDuration: 6000,
        });
      })
      .catch((e) => {
        console.log(e);
        setAppState((state) => ({
          ...state,
          isLoading: false,
        }));

        setToastState({
          open: true,
          message: "Errore durante l'autenticazione",
          autoHideDuration: 600,
        });
      });
  };
  return (
    <Container
      sx={{
        mt: 4,
      }}
    >
      <Stack spacing={2}>
        <Box>
          <Button
            variant="outlined"
            onClick={() => {
              navigate('/');
            }}
            startIcon={<ArrowBack />}
          >
            Indietro
          </Button>
        </Box>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          textAlign={'center'}
        >
          Login
        </Typography>

        <GoogleButton
          onClick={() => {
            // setIsLoading(true);
            signInWithPopup(auth, provider)
              .then((result) => {
                /** @type {OAuthCredential} */
                const credential: OAuthCredential | null =
                  GoogleAuthProvider.credentialFromResult(result);

                // This gives you a Google Access Token. You can use it to access the Google API.
                const token = credential?.accessToken;
                // The signed-in user info.

                const user = result.user;
                // IdP data available in result.additionalUserInfo.profile.
                // ...
                //  setIsLoading(false);
                googleAuthApi()
                  .then(() => {
                    console.log('Authenticated');
                    //setIsLoading(false);
                    setToastState({
                      open: true,
                      message: 'Autenticazione avvenuta con successo',
                      autoHideDuration: 6000,
                    });
                  })
                  .catch((e) => {
                    console.log(e);
                    //setIsLoading(false);
                  });
              })
              .catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                //setIsLoading(false);
                // ...
              })
              .finally(() => {
                //setIsLoading(false);
              });
          }}
          type="light"
          style={{
            boxShadow: 'none',
            border: '1.5px solid #e0e0e0',
            overflow: 'hidden',
            borderRadius: 'var(--border-radius)',
            width: '100%',
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'center',
            gap: 4,
          }}
        />

        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          required
          helperText={email.error}
          error={email.error !== ''}
          fullWidth
          value={email.value}
          onChange={(e) => {
            fieldValidator(
              userValidationFields.email,
              setEmail,
              e.target.value
            );
          }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={password.value}
          helperText={password.error}
          error={password.error !== ''}
          onChange={(e) => {
            fieldValidator(
              userValidationFields.password,
              setPassword,
              e.target.value
            );
          }}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          size="large"
          disabled={!isValid}
          sx={{ mt: 2 }}
          onClick={login}
        >
          Login
        </Button>
      </Stack>
    </Container>
  );
};
