import { createTheme } from '@mui/material/styles';
import { darken } from '@mui/system';

export const lecceTheme = createTheme({
  palette: {
    primary: {
      main: darken('#DBA416', 0),
    },
    secondary: {
      main: darken('#FFCB46', 0.2),
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#FFFFFF',
        },
      },
    },
  },
});
