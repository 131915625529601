import { styled } from '@mui/material/styles';
import { Box, InputProps } from '@mui/material';
import AutoBorderColorChangingInput from './AutoBorderColorChangingInput';
import { AIToggleButton } from './AIToggleButton';

interface SpecialSearchBarProps extends InputProps {
  buttonText: string;

  onClick?: () => void;
}

const Search = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
  border: '1px solid rgb(230, 230, 230)',
  borderRadius: 5,
  backgroundColor: 'rgb(245, 245, 245)',
}));

const SpecialSearchBar = ({
  onClick,
  buttonText,
  ...props
}: SpecialSearchBarProps) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flex={1}
    >
      <AutoBorderColorChangingInput {...props} />

      <AIToggleButton
        buttonText={buttonText}
        sx={{
          height: 65,
          ml: 0.7,
          mt: 0,
        }}
        onClick={onClick}
      />
    </Box>
  );
};

export default SpecialSearchBar;
