import { number } from 'yup';
import { httpRequest } from './base';

interface QueryData {
  query: string;
  sede?: string;
  tipo?: string;
  year?: string;
  numero?: string;
}

export const vectorSearchApi = (data: QueryData) => {
  return httpRequest({
    url: `${process.env.REACT_APP_SERVER_SEARCH_URL}/hybrid-search`,
    data,
    method: 'POST',
  });
};
