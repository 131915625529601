import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Grid2,
  lighten,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SmmaryLogo from '../../res/summary_logo.png';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import SynagogueRoundedIcon from '@mui/icons-material/SynagogueRounded';
import logo from '../../res/logo/giuribot_logo_extractor 2.png';
import { Fab } from '@mui/material';
import SupportIcon from '@mui/icons-material/Support';

export const Home = () => {
  const navigate = useNavigate();

  const [input, setInput] = useState<string>('');
  return (
    <Container>
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
      >
        <Fab
          color="primary"
          aria-label="contact support"
          onClick={() => {
            window.open('mailto:contact@giuribot.com');
          }}
        >
          <SupportIcon
            sx={{
              color: 'white',
            }}
          />
        </Fab>
      </Box>
      <Box width={'100%'} height={400} margin={'0px'} sx={{}} display={'flex'}>
        <Box width={'100%'} height={400} sx={{}}>
          <Box display={'flex'} justifyContent={'center'}>
            <Box mt={10} height={90} component={'img'} src={logo} />
          </Box>

          <Typography
            mt={3}
            fontSize={{
              xs: 20,
              sm: 25,
              md: 30,
            }}
            textAlign={'center'}
            color="rgb(80, 80, 80)"
          >
            Cercare Decisioni e Pareri della Giustizia Amministrativa
          </Typography>
          <Box margin={'0px 10%'}>
            <TextField
              fullWidth
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              placeholder="Inserisci le parole chiave che vuoi usare per trovare sentenze."
              sx={{
                mt: 1.5,

                borderRadius: '5px',
              }}
              InputProps={{
                endAdornment: (
                  <Button
                    disableElevation
                    onClick={() => {
                      navigate(
                        `/panel/giustizia-amministrativa?search=${input}`
                      );
                    }}
                  >
                    <Search />
                  </Button>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>

      <Divider
        sx={{
          my: 2,
        }}
      >
        <Typography
          px={10}
          fontSize={16}
          fontWeight={600}
          color="rgb(150, 150, 150)"
        >
          Alre funzioni
        </Typography>
      </Divider>
      <Grid2 marginTop={15} container spacing={2} mt={4}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Card elevation={0} variant="outlined">
            <Box
              height={150}
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
            >
              <SummarizeRoundedIcon
                sx={{
                  width: '60px',
                  height: '60px',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  color: 'rgb(219,164,22)',
                }}
              />
            </Box>

            <Typography textAlign={'center'} fontWeight={600}>
              RIASSUNTI DI DOCUMENTI
            </Typography>

            <CardContent>
              <Box>
                <p>
                  Estrai automaticamente i riassunti dei documenti giuridici per
                  avere una visione d'insieme dei contenuti.
                </p>
              </Box>
            </CardContent>
            <CardActionArea disableRipple disableTouchRipple>
              <Button
                fullWidth
                disableElevation
                variant="contained"
                onClick={() => {
                  navigate('/panel/chat-docs');
                }}
              >
                Andare
              </Button>
            </CardActionArea>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Card elevation={0} variant="outlined">
            <Box
              height={150}
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
            >
              <ListAltRoundedIcon
                sx={{
                  width: '60px',
                  height: '60px',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  color: 'rgb(219,164,22)',
                }}
              />
            </Box>

            <Typography textAlign={'center'} fontWeight={600}>
              ESTRAZIONE STRUTTURATA DI DATI
            </Typography>

            <CardContent>
              <Box>
                <p>
                  Estrai automaticamente dati strutturati dai documenti
                  giuridici per facilitare l'analisi e l'elaborazione delle
                  informazioni.
                </p>
              </Box>
            </CardContent>
            <CardActionArea disableRipple disableTouchRipple>
              <Button
                fullWidth
                disableElevation
                onClick={() => {
                  navigate('/panel/structured-output');
                }}
                variant="contained"
              >
                Andare
              </Button>
            </CardActionArea>
          </Card>
        </Grid2>
        {true !== true ? (
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Card elevation={0} variant="outlined">
              <Box
                height={150}
                width={'100%'}
                display={'flex'}
                justifyContent={'center'}
              >
                <SynagogueRoundedIcon
                  sx={{
                    width: '60px',
                    height: '60px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    color: 'rgb(219,164,22)',
                  }}
                />
              </Box>

              <Typography textAlign={'center'} fontWeight={600}>
                COMPILAZIONE DI TEMPLATE
              </Typography>

              <CardContent>
                <Box>
                  <p>
                    Compila automaticamente i template giuridici con i dati
                    estratti dai documenti, risparmiando tempo e riducendo gli
                    errori.
                  </p>
                </Box>
              </CardContent>
              <CardActionArea disableRipple disableTouchRipple>
                <Button disableElevation fullWidth variant="contained">
                  Andare
                </Button>
              </CardActionArea>
            </Card>
          </Grid2>
        ) : null}
      </Grid2>

      <Box height={100}></Box>
    </Container>
  );
};
