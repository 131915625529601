import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Link,
  IconButton,
} from '@mui/material';
import { GitHub, LinkedIn, Twitter, X, Instagram } from '@mui/icons-material';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        bgcolor: 'rgb(235, 235, 235)',
        p: 6,
        mt: 'auto',
        color: 'text.secondary',
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
      component="footer"
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* First Column: About */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" fontWeight={600} gutterBottom>
              Chi Siamo
            </Typography>
            <Typography variant="body2">
              Siamo un team di appassionati che si preoccupa profondamente dei
              problemi delle persone comuni e crediamo che ci sia ancora molto
              spazio per sfruttare le nuove tecnologie per affrontarli.
            </Typography>
          </Grid>

          {/* Second Column: Navigation */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" fontWeight={600} gutterBottom>
              Navigazione
            </Typography>
            <nav>
              <Link
                href="/"
                color="inherit"
                sx={{ display: 'block', mb: 1, cursor: 'pointer' }}
              >
                Home
              </Link>

              <Link
                color="inherit"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    window.location.origin + '/terms-and-conditions',
                    '_blank'
                  );
                }}
                sx={{ display: 'block', mb: 1, cursor: 'pointer' }}
              >
                Termino e Condizioni / Privacy Policy
              </Link>
            </nav>
          </Grid>

          {/* Third Column: Social Media */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" fontWeight={600} gutterBottom>
              Seguici
            </Typography>

            <IconButton
              aria-label="X"
              href="https://x.com/giuribot"
              target="_blank"
              color="inherit"
            >
              <X />
            </IconButton>

            <IconButton
              aria-label="Instagram"
              href="https://www.instagram.com/giuribot"
              target="_blank"
              color="inherit"
            >
              <Instagram />
            </IconButton>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box mt={5}>
          <Typography variant="body2" align="center">
            {'Copyright © '} {currentYear} Solfop LLC. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
