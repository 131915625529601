import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { ChatHistory } from '../../../models/chat_history.model';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { useState } from 'react';

interface SavedChatItemProps {
  data: ChatHistory;
  isSelected: boolean;
  onClick: (data: ChatHistory) => void;
  onDeleted: (data: ChatHistory) => void;
}

export const SavedChatItem = ({
  data,
  isSelected,
  onClick,
  onDeleted,
}: SavedChatItemProps) => {
  // State to manage whether the menu is open or not
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Handler for opening the menu
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // Handler for closing the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = ['Rimuovere'];

  return (
    <div>
      <Button
        sx={{
          width: '100%',

          textAlign: 'left',
          alignItems: 'start',
          justifyContent: 'space-between',
          textTransform: 'none',
          color: isSelected ? 'primary.light' : 'black',
        }}
        onClick={() => onClick(data)}
      >
        {data.title}
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          size="small"
          style={{ borderRadius: '50%' }}
        >
          {' '}
          <MoreVertRoundedIcon
            sx={{
              width: '20px',
            }}
          />
        </IconButton>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 1400 }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        // Positioning the menu directly below and aligned with the button
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              onDeleted(data);
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
