import {
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import GoogleButton from 'react-google-button';
import { app } from '../../services/firebaseSetUp';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthCredential,
  signInWithPopup,
} from 'firebase/auth';
import { googleAuthApi, registerUserApi } from '../../api/users';
import { useRecoilState } from 'recoil';
import { toastAtom } from '../../state/toast.atom';
import { appAtom } from '../../state/app.atom';
import { ValueModel } from '../../models/value.model';
import { fieldValidator } from '../../models/validation.model';
import { userValidationFields } from '../../models/user.model';
import { set } from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { PrivacyPolicyDialog } from '../PrivacyPolicy/PrivacyPolicyDialog';

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const SignUp = () => {
  const [toastState, setToastState] = useRecoilState(toastAtom);
  const [appState, setAppState] = useRecoilState(appAtom);

  const [isValid, setIsValid] = React.useState(false);

  const [name, setName] = React.useState<ValueModel<string>>({
    valid: false,
    value: '',
    error: '',
  });
  const [email, setEmail] = React.useState<ValueModel<string>>({
    valid: false,
    value: '',
    error: '',
  });
  const [password, setPassword] = React.useState<ValueModel<string>>({
    valid: false,
    value: '',
    error: '',
  });
  const [confirmPassword, setConfirmPassword] = React.useState<
    ValueModel<string>
  >({
    valid: false,
    value: '',
    error: '',
  });

  const [dialogTermsAndConditions, setDialogTermsAndConditions] =
    useState(false);

  const [acceptTerms, setAcceptTerms] = React.useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      name.valid &&
      email.valid &&
      password.valid &&
      confirmPassword.valid &&
      acceptTerms
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  });

  const register = () => {
    if (name.valid && email.valid && password.valid && confirmPassword.valid) {
      setAppState((state) => ({
        ...state,
        isLoading: true,
      }));
      registerUserApi({
        name: name.value,
        email: email.value,
        password: password.value,
      })
        .then((response) => {
          setToastState({
            open: true,
            message: 'Registrazione avvenuta con successo',
            autoHideDuration: 6000,
          });

          setAppState((state) => ({
            ...state,
            isLoading: false,
          }));

          setName({ valid: false, value: '', error: '' });
          setEmail({ valid: false, value: '', error: '' });
          setPassword({ valid: false, value: '', error: '' });
          setConfirmPassword({ valid: false, value: '', error: '' });
          navigate('/login');
        })
        .catch((error) => {
          setToastState({
            open: true,
            message:
              'Si è verificato un errore durante la registrazione. Per favore riprova.',
            autoHideDuration: 6000,
          });

          setAppState((state) => ({
            ...state,
            isLoading: false,
          }));
        });
    } else {
      setToastState({
        open: true,
        message: 'Compila tutti i campi correttamente',
        autoHideDuration: 6000,
      });
    }
  };
  return (
    <Container
      sx={{
        mt: 4,
      }}
    >
      <PrivacyPolicyDialog
        approveOrDeny={(result: boolean) => {
          setAcceptTerms(result);
          setDialogTermsAndConditions(false);
        }}
        open={dialogTermsAndConditions}
        handleClose={() => {
          setDialogTermsAndConditions(false);
        }}
      />

      <Stack spacing={2}>
        <Box>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={() => {
              navigate('/');
            }}
          >
            Indietro
          </Button>
        </Box>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          textAlign={'center'}
        >
          Registrati
        </Typography>

        <GoogleButton
          onClick={() => {
            if (!acceptTerms) {
              setDialogTermsAndConditions(true);
              return;
            }
            // setIsLoading(true);
            signInWithPopup(auth, provider)
              .then((result) => {
                /** @type {OAuthCredential} */
                const credential: OAuthCredential | null =
                  GoogleAuthProvider.credentialFromResult(result);

                // This gives you a Google Access Token. You can use it to access the Google API.
                const token = credential?.accessToken;
                // The signed-in user info.

                const user = result.user;
                // IdP data available in result.additionalUserInfo.profile.
                // ...
                //  setIsLoading(false);
                googleAuthApi()
                  .then(() => {
                    console.log('Authenticated');
                    //setIsLoading(false);
                    setToastState({
                      open: true,
                      message: 'Authenticated successfully',
                      autoHideDuration: 6000,
                    });
                  })
                  .catch((e) => {
                    console.log(e);
                    //setIsLoading(false);
                  });
              })
              .catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                //setIsLoading(false);
                // ...
              })
              .finally(() => {
                //setIsLoading(false);
              });
          }}
          type="light"
          style={{
            boxShadow: 'none',
            border: '1.5px solid #e0e0e0',
            overflow: 'hidden',
            borderRadius: 'var(--border-radius)',
            width: '100%',
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'center',
            gap: 4,
          }}
        />

        <TextField
          label="Nome"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={name.value}
          helperText={name.error}
          error={name.error !== ''}
          onChange={(e) => {
            fieldValidator(userValidationFields.name, setName, e.target.value);
          }}
        />

        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          required
          helperText={email.error}
          error={email.error !== ''}
          fullWidth
          value={email.value}
          onChange={(e) => {
            fieldValidator(
              userValidationFields.email,
              setEmail,
              e.target.value
            );
          }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={password.value}
          helperText={password.error}
          error={password.error !== ''}
          onChange={(e) => {
            fieldValidator(
              userValidationFields.password,
              setPassword,
              e.target.value
            );
          }}
        />
        <TextField
          label="Conferma la password"
          type="password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={confirmPassword.value}
          helperText={confirmPassword.error}
          error={confirmPassword.error !== ''}
          onChange={(e) => {
            // fieldValidator(
            //   userValidationFields.password,
            //   setConfirmPassword,
            //   e.target.value
            // );

            if (e.currentTarget.value !== password.value) {
              setConfirmPassword({
                value: e.currentTarget.value,
                error: 'Le password non corrispondono',
                valid: false,
              });
            } else {
              setConfirmPassword({
                value: e.currentTarget.value,
                error: '',
                valid: true,
              });
            }
          }}
        />

        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Checkbox
            checked={acceptTerms}
            onChange={(e) => {
              setDialogTermsAndConditions(true);
            }}
          />

          <Typography>
            <Link
              to={'/terms-and-conditions'}
              onClick={(e) => {
                e.preventDefault();
                setDialogTermsAndConditions(true);
              }}
            >
              {' '}
              Accetto i termini e condizioni e l'informativa sulla privacy
            </Link>
          </Typography>
        </Stack>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          size="large"
          disabled={!isValid}
          sx={{ mt: 2 }}
          onClick={register}
        >
          Registrati
        </Button>
      </Stack>
    </Container>
  );
};
