import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import withRoot from '../../../modules/withRoot';
import PricingCard from '../components/PricingCard';
import { useTranslation } from 'react-i18next';
import { extendedTheme } from '../../../themes/extendedTheme';
import { useNavigate } from 'react-router-dom';

function PricingPage() {
  const { t } = useTranslation();

  const primaryColor = extendedTheme.colorSchemes.light!.palette.primary.main;

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Container
        id="pricing"
        component="section"
        sx={{
          px: {
            xs: 5,
            sm: 10,
            md: 20,
            lg: 20,
            xl: 20,
          },
          py: '60px',
          backgroundColor: '#f5f5f5',
          minWidth: '100vw',
        }}
      >
        <Typography
          variant="h4"
          align="center"
          component="h2"
          color={primaryColor}
        >
          {t('pricing_uppercase')}
        </Typography>

        <Box
          sx={{
            width: '100%',
            display: 'grid',
            justifyContent: 'center',
            gridTemplateColumns: {
              // 3 columns for larger screens
              xs: '1fr', // default to 1 column for the smallest screens
              sm: 'repeat(2, 1fr)', // 2 columns for small screens
              lg: 'repeat(3, minmax(min(100%, 250px), 350px))',
              xg: 'repeat(3, minmax(min(100%, 250px), 350px))', // 3 columns for medium and larger screens
            },
            gap: 3,

            mt: 10,
          }}
        >
          <PricingCard
            title={t('plan_01_header')}
            features={[
              '50 richerche di sentenze / al mese',
              'Estrazione strutturata di 200 documenti / al mese',
              'Chatta con 30 documenti / al mese',
              'Supporto base',
            ]}
            description=""
            price={'5'}
            label={t('plan_01_title')}
            buttonText={t('start_uppercase')}
            onClick={() => {
              navigate('/register');
            }}
            isFree={false}
            href={`${process.env.REACT_APP_ADMIN_URL}/register?plan=free`}
          />

          <PricingCard
            title={t('plan_02_header')}
            features={[
              '500 richerche di sentenze / al mese',
              'Estrazione strutturata di 1000 documenti / al mese',
              'Chatta con 100 documenti / al mese',
              'Supporto avanzato',
            ]}
            description=""
            price="10"
            label={t('plan_02_title')}
            buttonText={t('start_uppercase')}
            onClick={() => {
              navigate('/register');
            }}
            isFree={false}
            href={`${process.env.REACT_APP_ADMIN_URL}/register?plan=basic`}
          />

          <PricingCard
            title={t('plan_03_header')}
            features={[
              '2000 richerche di sentenze / al mese',
              'Estrazione strutturata di 2500 documenti / al mese',
              'Chatta con 250 documenti / al mese',
              'Supporto pro',
            ]}
            description=""
            price="25"
            label={t('plan_03_title')}
            buttonText={t('start_uppercase')}
            onClick={() => {
              navigate('/register');
            }}
            headerColor={primaryColor}
            isFree={false}
            href={`${process.env.REACT_APP_ADMIN_URL}/register?plan=pro`}
          />
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default withRoot(PricingPage);
