import {
  createTextWindows,
  extractQueryTerms,
  normalizeText,
} from './text_matching_processor';

interface HighlightedFragment {
  text: string;
  elements: JSX.Element[];
}

// Modified TextFragment interface to track matches
interface TextFragment {
  text: string;
  score: number;
  words: string[];
  matches: Set<number>; // Stores indices of matched words
}

// Modified findBestMatchingFragment function
export function findBestMatchingFragmentComponent(
  query: string,
  resultText: string,
  windowSize: number = 100,
  step: number = 50
): TextFragment {
  const queryTerms = extractQueryTerms(query);
  const resultWords = normalizeText(resultText);
  const fragments = createTextWindows(resultWords, windowSize, step);

  let bestFragment: TextFragment = {
    text: '',
    score: -1,
    words: [],
    matches: new Set<number>(),
  };

  fragments.forEach((fragmentWords) => {
    const { score, matches } = calculateFragmentScore(
      fragmentWords,
      queryTerms
    );
    if (score > bestFragment.score) {
      bestFragment = {
        text: fragmentWords.join(' '),
        score,
        words: fragmentWords,
        matches,
      };
    }
  });

  return bestFragment;
}

// Modified scoring function to track matches
function calculateFragmentScore(
  fragment: string[],
  queryTerms: string[][]
): { score: number; matches: Set<number> } {
  let score = 0;
  const matches = new Set<number>();

  queryTerms.forEach((term) => {
    const termLength = term.length;

    // Handle multi-word phrases
    if (termLength > 1) {
      for (let i = 0; i <= fragment.length - termLength; i++) {
        if (term.every((word, j) => word === fragment[i + j])) {
          score += termLength * 2; // Extra weight for phrases
          for (let j = 0; j < termLength; j++) {
            matches.add(i + j);
          }
        }
      }
    }
    // Handle single words
    else {
      const word = term[0];
      fragment.forEach((fragmentWord, index) => {
        if (fragmentWord === word) {
          score += 1;
          matches.add(index);
        }
      });
    }
  });

  return { score, matches };
}

// Updated createHighlightedFragment function
export function createHighlightedFragment(
  fragment: TextFragment
): HighlightedFragment {
  const elements = fragment.words.map((word, index) => {
    const isMatch = fragment.matches.has(index);
    return (
      <span
        key={index}
        style={{
          backgroundColor: isMatch ? '#a7e7ff' : 'transparent',
          padding: isMatch ? '2px 3px' : '0px',
          borderRadius: '3px',
          fontWeight: isMatch ? 'bold' : 'normal',
        }}
      >
        {word}
      </span>
    );
  });

  // Fixed rendering with proper spacing
  const spacedElements = elements.reduce<JSX.Element[]>(
    (acc: any, element, index, array): JSX.Element[] => {
      if (index === 0) return [element];
      return [...acc, ' ', element];
    },
    []
  );

  return {
    text: fragment.text,
    elements: [
      <div
        key="fragment"
        style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
      >
        {spacedElements}
      </div>,
    ],
  };
}

export function createHighlightedFragmentContext(
  fragment: TextFragment
): HighlightedFragment {
  const elements = fragment.words.map((word, index) => {
    const isMatch = fragment.matches.has(index);
    return (
      <span
        key={index}
        style={{
          backgroundColor: isMatch ? '#a7e7ff' : 'transparent',

          margin: 0,
          padding: isMatch ? '0px 3px' : 0,
          borderRadius: '3px',
          fontWeight: isMatch ? 'bold' : 'normal',
          fontSize: 12,
          lineHeight: '0.1rem',
        }}
      >
        {word}
      </span>
    );
  });

  // Fixed rendering with proper spacing
  const spacedElements = elements.reduce<JSX.Element[]>(
    (acc: any, element, index, array): JSX.Element[] => {
      if (index === 0) return [element];
      return [...acc, ' ', element];
    },
    []
  );

  return {
    text: fragment.text,
    elements: [
      <div
        key="fragment"
        style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
      >
        {spacedElements}
      </div>,
    ],
  };
}
