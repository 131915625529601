import axios, { AxiosRequestConfig } from 'axios';
import { app } from '../services/firebaseSetUp';
import { getAuth } from 'firebase/auth';

const auth = getAuth(app);

interface HttpRequestConfig extends AxiosRequestConfig {
  authToken?: string;
  path?: string;
}

export async function httpRequest(config: HttpRequestConfig): Promise<any> {
  const {
    url = process.env.REACT_APP_SERVER_BASE_URL,
    method = 'GET',
    authToken,
    headers,
    data,
    path,
  } = config;
  const defaultHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  const tokenFirebase = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : null;

  if (tokenFirebase) {
    defaultHeaders['Authorization'] = `Bearer ${tokenFirebase}`;
  } else if (authToken) {
    defaultHeaders['Authorization'] = `Bearer ${authToken}`;
  }

  const response = await axios({
    url: path ? url + path : url,
    method,
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    data: data,
  });

  return response;
}
