import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Markdown from 'marked-react';
import { privacyPolicyMarkdown } from './PrivacyPolicyMarkdown';
import { Box } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PrivacyPolicyDialog = ({
  open,
  handleClose,
  approveOrDeny,
}: {
  open: boolean;
  handleClose: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void | Function;
  approveOrDeny: Function;
}) => {
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ bgcolor: 'white' }}
          variant="outlined"
          elevation={0}
          position="sticky"
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose as any}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Termini e condizioni / Privacy Policy
            </Typography>
          </Toolbar>
        </AppBar>
        <Box p={4}>
          <Box>
            <Markdown>{privacyPolicyMarkdown}</Markdown>
          </Box>
        </Box>

        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            right: 0,
            display: 'flex',
            gap: 2,
            py: 2,
            width: '100%',
            backgroundColor: 'white',
            justifyContent: 'center',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              approveOrDeny(true);
            }}
            disableElevation
          >
            Accetta
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            onClick={() => {
              approveOrDeny(false);
            }}
            disableElevation
          >
            Rifiuta
          </Button>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};
