import * as Yup from 'yup';

export interface User {
  email: string;
  name: string;
  plan: any | null;
  customer_id: string;
  quota: number;
  api_key: string;
  created_at: Date;
  updated_at: Date;
  picture: string;
}

export interface UserRegistration {
  email: string;
  name: string;
  password: string;
}

export const userValidationFields = {
  businessName: Yup.string().required("Il nome dell'azienda è obbligatorio"),
  type: Yup.string().required('Il tipo è obbligatorio'),
  branches: Yup.number()
    .required('Il numero di filiali è obbligatorio')
    .min(1, 'È richiesta almeno una filiale'),
  name: Yup.string()
    .required('Il nome è obbligatorio')
    .min(2, 'Il nome deve essere lungo almeno 2 caratteri'),
  last_name: Yup.string()
    .required('Il cognome è obbligatorio')
    .min(2, 'Il cognome deve essere lungo almeno 2 caratteri'),
  phone: Yup.string()
    .required('Il numero di telefono è obbligatorio')
    .matches(
      /^\+?[0-9]+$/,
      'Il numero di telefono deve essere numerico e può iniziare con il segno più'
    ),
  email: Yup.string()
    .required("L'email è obbligatoria")
    .email('Formato email non valido'),
  password: Yup.string()
    .required('La password è obbligatoria')
    .min(8, 'La password deve essere lunga almeno 8 caratteri'),
  address: Yup.string().required("L'indirizzo è obbligatorio"),
  tax_number: Yup.string()
    .length(16, 'Il codice fiscale deve essere lungo 16 caratteri')
    .matches(
      /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/,
      'Formato del codice fiscale non valido'
    )
    .required('Il codice fiscale è obbligatorio'),
};
