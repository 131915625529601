import React, { ReactNode, useState } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

interface HistoryPanelContainerProps {
  children: ReactNode;
}

// Styled component for the element with transition
const TransitionBox = styled(Box)({
  transition: 'width 0.3s ease-in-out',
  backgroundColor: 'white',
  borderRadius: '10px',
  marginTop: '15px',
  border: '1px solid #ccc',
});

const HistoryPanelContainer = ({ children }: HistoryPanelContainerProps) => {
  const [width, setWidth] = useState<number>(200);

  const toggleWidth = () => {
    setWidth((prevWidth) => (prevWidth === 50 ? 200 : 50));
  };

  return (
    <div>
      <TransitionBox
        height={{
          sm: 'auto',
          md: 'auto',
          lg: 'calc(100vh - 317px)',
        }}
        width={{
          sm: '100%',
          md: '100%',
          lg: width,
        }}
        marginRight={1}
      >
        {/*
        <Box display={'flex'} justifyContent={'end'}>
          <Button
            color="primary"
            onClick={toggleWidth}
            style={{ marginTop: '10px' }}
            sx={{
              width: '50px',
            }}
          >
            {width == 50 ? (
              <KeyboardArrowRightRoundedIcon />
            ) : (
              <ChevronLeftRoundedIcon />
            )}
          </Button>
        </Box>
        */}
        {children}
      </TransitionBox>
    </div>
  );
};

export default HistoryPanelContainer;
