import { atom } from 'recoil';
import { User } from '../models/user.model';

interface ApiKeyData {
  quota: number;
  email: string;
  session: string;
}

interface LastSession {
  id: string;
  results: any[];
  fieldsTemplate: {
    field: string;
    value: string;
  }[];
}

interface FieldsTemplate {
  field: string;
  value: string;
}

export interface AppState {
  apiKey: string;
  apiKeyData: ApiKeyData | null;
  isApiKeyValid: boolean;
  isLoading: boolean;
  tabValue: string;
  testMode: boolean;
  toUpdate: boolean;
  files: File[] | null;
  documentsType: 'text' | 'scanned' | '';
  lastSession: LastSession[];
  loadingSession: boolean;
  results: any[];
  fieldsTemplate: FieldsTemplate[];
  user: User | null;
  plan: {
    name: string;
    price: number;
    quota: number;
  } | null;
}

export const appAtom = atom<AppState>({
  key: 'appState',
  default: {
    plan: null,
    user: null,
    apiKey: '',
    apiKeyData: {
      quota: 0,
      email: '',
      session: '',
    } as { quota: number; email: string; session: string } | null,
    isApiKeyValid: false,
    isLoading: false,
    tabValue: '1',
    testMode: false,
    toUpdate: false,
    files: [] as File[] | null,
    documentsType: 'text' as 'text' | 'scanned' | '',
    lastSession: [] as {
      id: string;
      results: any[];
      fieldsTemplate: {
        field: string;
        value: string;
      }[];
    }[],
    loadingSession: false,
    results: [] as any[],
    fieldsTemplate: [
      {
        field: 'anno',
        value: 'Anno della sentenza',
      },
      {
        field: 'numero',
        value: 'Numero della sentenza',
      },
      {
        field: 'oggetto',
        value: 'Oggetto della sentenza',
      },
      {
        field: 'tipologiaAtto',
        value: "Tipologia dell'atto",
      },
      {
        field: 'soggettoRicorrente',
        value: 'Soggetto ricorrente',
      },
      {
        field: 'soggettoResistente',
        value: 'Soggetto resistente',
      },
      {
        field: 'numeroDiRegistro',
        value: 'Numero di registro',
      },
    ] as {
      field: string;
      value: string;
    }[],
  },
});
