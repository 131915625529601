import React from 'react';
import { useRecoilState } from 'recoil';
import { appAtom } from '../../state/app.atom';
import { checkApiKeyExistsApi } from '../../api/key.api';
import { useNavigate } from 'react-router-dom';
import AppAppBar from './views/AppAppBar';
import ProductHero from './views/ProductHero';
import WhySection from './views/WhySection';
import ProductCategories from './views/ProductCategories';
import PricingPage from './views/PricingPage';
import AppFooter from './views/AppFooter';
import FAQSection from './views/FAQSection';
import ProductSmokingHero from './views/ProductSmokingHero';

export const Landing = () => {
  const [appState, setAppState] = useRecoilState(appAtom);

  const navigate = useNavigate();

  const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppState((prevState) => ({
      ...prevState,
      apiKey: event.target.value,
    }));
  };

  const fetchUserIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip; // Returns the IP address
    } catch (error) {
      console.error('Error fetching IP:', error);
      return null;
    }
  };

  const handleApiKeySubmit = () => {
    setAppState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    if (!appState.apiKey.trim()) {
      alert('Devi inserire una chiave API valida');
      setAppState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return;
    }

    checkApiKeyExistsApi(appState.apiKey).then((response) => {
      if (response.exists) {
        setAppState((prevState) => ({
          ...prevState,
          isApiKeyValid: true,
        }));
        setAppState((prevState) => ({
          ...prevState,
          apiKeyData: { ...response.value, session: new Date().toISOString() },
        }));
      } else {
        alert("Chiave API non valida. Contatta l'amministratore.");
      }
      setAppState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    });
  };

  return (
    <React.Fragment>
      <AppAppBar window={window} hideNavigation={false} />
      <ProductHero />
      <WhySection />
      <ProductCategories />
      <PricingPage />
      <FAQSection />
      <ProductSmokingHero />
      <AppFooter />
    </React.Fragment>
  );
};
