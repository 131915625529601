import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import { Box, Chip, Container } from '@mui/material';
import { extendedTheme } from '../../../themes/extendedTheme';
import { useTranslation } from 'react-i18next';

import SpecialSearchBar from '../components/SpecialSearchBar';
import { ChangeEvent, useState } from 'react';

//import testVideo from "../../assets/vi";

export default function ProductHero() {
  const { t, i18n } = useTranslation();

  const primaryColor = extendedTheme.colorSchemes.light!.palette.primary.main;

  const [search, setSearch] = useState('');

  function handleSearchChange(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    setSearch(event.target.value);
    console.log(`Search Value assigned: ${event.target.value}`);
  }

  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundColor: 'white',
      }}
    >
      <Container
        id="home"
        sx={{
          px: {
            xs: 5,
            sm: 10,
            md: 30,
            lg: 30,
            xl: 30,
          },
        }}
      >
        <Typography
          fontSize={{
            xs: 45,
            sm: 45,
            md: 45,
            lg: 55,
            xl: 55,
          }}
          textAlign={'center'}
          fontWeight={700}
        >
          {t('section_01_title')}
        </Typography>
        <Typography
          color="black"
          align="center"
          variant="h5"
          textAlign={'center'}
          sx={{
            mb: 10,

            mt: { xs: 1, sm: 1 },
          }}
        >
          {t('section_01_subtitle')}
        </Typography>
      </Container>

      <Box mt={2} mb={5} mx={20}></Box>

      {/*<VideoPlayer src={'../../assets/'} />*/}
    </ProductHeroLayout>
  );
}
