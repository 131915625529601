import { Box, Button, Grid2, TextField, Typography } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';
import { useRecoilState } from 'recoil';
import { appAtom } from '../../state/app.atom';
import logo from '../../res/logo/giuribot_logo_extractor 2.png';
import { ArrowForward } from '@mui/icons-material';
import { checkApiKeyExistsApi, getOrCreateApiKey } from '../../api/key.api';
import { useNavigate } from 'react-router-dom';
import wallpaper from '../../res/wallpaper.jpg';
import Markdown from 'marked-react';

export const Landing = () => {
  const [appState, setAppState] = useRecoilState(appAtom);

  const navigate = useNavigate();

  const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppState((prevState) => ({
      ...prevState,
      apiKey: event.target.value,
    }));
  };

  const fetchUserIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip; // Returns the IP address
    } catch (error) {
      console.error('Error fetching IP:', error);
      return null;
    }
  };

  const handleApiKeySubmit = () => {
    setAppState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    if (!appState.apiKey.trim()) {
      alert('Devi inserire una chiave API valida');
      setAppState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return;
    }

    checkApiKeyExistsApi(appState.apiKey).then((response) => {
      if (response.exists) {
        setAppState((prevState) => ({
          ...prevState,
          isApiKeyValid: true,
        }));
        setAppState((prevState) => ({
          ...prevState,
          apiKeyData: { ...response.value, session: new Date().toISOString() },
        }));
      } else {
        alert("Chiave API non valida. Contatta l'amministratore.");
      }
      setAppState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    });
  };

  const presentationText = `
# Benvenuti in GiuriBot

Lo strumento innovativo che rende il lavoro con i documenti legali più semplice ed efficace!

## Cosa fa GiuriBot?

- **Trasforma i documenti in tabelle**: Carica il tuo file, e GiuriBot lo convertirà in tabelle ben strutturate con colonne, pronte per essere utilizzate o analizzate.

- **Riassunti accurati dei documenti giuridici**: Grazie a un vasto database di documenti legali, GiuriBot “capisce” meglio il linguaggio giuridico, aiutandoti a ottenere riassunti più precisi e utili.

- **Ricerca intelligente delle sentenze**: La ricerca non è solo letterale! GiuriBot comprende il significato delle parole, sinonimi e il contesto delle frasi, garantendoti risultati pertinenti anche se usi termini diversi da quelli presenti nel testo della sentenza.

**Carica il tuo file e lascia che GiuriBot faccia il resto!**
`;

  return (
    <Grid2
      container
      sx={{
        height: '100%',
      }}
      spacing={4}
    >
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{
          height: {
            xs: 'auto',
            md: '100%',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${wallpaper})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          {/* <Box
            sx={{
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 6px 6px 0 rgba(0,0,0,0.2)',
            }}
          >
            <ReactPlayer
              url={'./giuri-extractor-presentazione.mp4'}
              controls
              playing={false}
            />
          </Box> */}
        </Box>
        {/* <Box
              sx={{
                height: '100%',
                backgroundImage: `url(${presentatioBacground})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
              }}
            ></Box> */}
      </Grid2>

      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{
          height: {
            xs: 'auto',
            md: '100%',
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            px: 4,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height={'100%'}
          >
            <img src={logo} alt="GiuriBot" style={{ width: '300px' }} />
            {/* <Typography variant="h3" mb={2} textAlign={'center'}>
                  GiuriBot Extractor
                </Typography> */}
            <Box mt={2}>
              <Markdown>{presentationText}</Markdown>
            </Box>

            {true !== true ? (
              <TextField
                label="Chiave di accesso"
                variant="outlined"
                value={appState.apiKey}
                onChange={handleApiKeyChange}
                margin="normal"
                sx={{
                  width: '300px',
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleApiKeySubmit();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={handleApiKeySubmit}
                      size="large"
                      disableElevation
                      variant="contained"
                      color="primary"
                    >
                      <ArrowForward />
                    </Button>
                  ),
                }}
              />
            ) : null}

            {/* <Button
              size="large"
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => (window.location.pathname = '/payment.html')}
              style={{ marginTop: '16px' }}
            >
              Crea account
            </Button> */}

            <Button
              size="large"
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => {
                navigate('/login');
              }}
              style={{ marginTop: '16px' }}
            >
              Login
            </Button>

            <Button
              size="large"
              disableElevation
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate('/register');
              }}
              style={{ marginTop: '16px' }}
            >
              Crea account
            </Button>
            {/* 
            <Button
              size="large"
              disableElevation
              variant="outlined"
              color="primary"
              onClick={async () => {
                const userIP = await fetchUserIP(); // Fetch the user's IP

                if (userIP) {
                  const apiKeyData = await getOrCreateApiKey(userIP); // Pass the IP to your function
                  setAppState((prevState: any) => ({
                    ...prevState,
                    apiKey: apiKeyData.apiKey,
                    isApiKeyValid: true,
                    apiKeyData: {
                      ...apiKeyData,
                      session: new Date().toISOString(),
                    },
                  }));
                } else {
                  alert('Unable to fetch user IP');
                }
              }}
              style={{ marginTop: '16px' }}
            >
              Prova l'applicazione
            </Button> */}
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  );
};
