import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { app } from '../services/firebaseSetUp';
import { ChatHistory } from '../models/chat_history.model';
import { getAuth } from 'firebase/auth';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

//Function to save in firebase a new chat
export const saveSummaryChatHistory = async (
  chat: ChatHistory,
  files: File[]
): Promise<void> => {
  try {
    const userDocRef = doc(db, 'users', auth.currentUser!.uid);
    const subcollectionRef = collection(userDocRef, 'summaryChatHistory');

    const newDoc = doc(subcollectionRef);
    chat.id = newDoc.id;

    if (!files) return;

    const uploadPromises = Array.from(files).map(async (file) => {
      const storageRef = ref(
        storage,
        `users/${auth.currentUser!.uid}/files/${file.name}`
      );
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return { path: snapshot.ref.fullPath, url: downloadURL };
    });

    const results = await Promise.all(uploadPromises);

    chat.filesPaths = results.map((e) => {
      return e.path;
    });
    chat.filesUrls = results.map((e) => {
      return e.url;
    });

    await setDoc(newDoc, chat);
  } catch (e) {
    console.error('Error registering chat history: ', e);
  }
};

export const updateChatHistory = async (chat: ChatHistory) => {
  try {
    const userDocRef = doc(db, 'users', auth.currentUser!.uid);
    const subcollectionRef = collection(userDocRef, 'summaryChatHistory');

    const newDoc = doc(subcollectionRef, chat.id);

    await updateDoc(newDoc, { messages: chat.messages });
  } catch (e) {
    console.error('Error registering chat history: ', e);
  }
};

//Function to load the chat history form the chat summary section
export const loadSummaryChatHistory = async (): Promise<
  ChatHistory[] | null
> => {
  const userId = auth.currentUser!.uid;

  const mainDocRef = collection(db, 'users', userId, 'summaryChatHistory');
  const q = query(mainDocRef, orderBy('date', 'desc'), limit(10)); // Limit to 10 for performance

  try {
    const querySnapshot = await getDocs(q);
    //Here we convert the docs in a list of ChatHistory
    const docs = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
        } as ChatHistory)
    );

    return docs;
  } catch (error) {
    console.error('Error fetching documents:', error);
    return null;
  }
};

export const deleteChatFromHistory = async (
  data: ChatHistory
): Promise<void> => {
  const userDocRef = doc(db, 'users', auth.currentUser!.uid);
  const historySubcollectionRef = collection(userDocRef, 'summaryChatHistory');

  const chatDocRef = doc(historySubcollectionRef, data.id);

  if (data.filesPaths != null) {
    const deletePromises = data.filesPaths.map(async (fileUrlOrPath) => {
      let storageRef = ref(storage, fileUrlOrPath);

      return deleteObject(storageRef);
    });

    await Promise.all(deletePromises);
  }

  try {
    await deleteDoc(chatDocRef);
  } catch (e) {
    console.error('Error deleting document:', e);
  }
};
