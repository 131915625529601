import { createTheme } from '@mui/material/styles';
import { darken } from '@mui/system';

export const lecceTheme = createTheme({
  palette: {
    primary: {
      main: darken('#2395ff', 0),
    },
    secondary: {
      main: darken('#2395ff', 0.2),
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#FFFFFF',
        },
      },
    },
  },
});
