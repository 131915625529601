import {
  AppBar,
  Avatar,
  Box,
  Breadcrumbs,
  Grid2,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Home } from '../Home/Home';
import { StructuredOutput } from '../StructuredOutput/StructuredOutput';
import { appAtom } from '../../state/app.atom';
import { useRecoilState } from 'recoil';
import { getAuth, signOut } from 'firebase/auth';
import { app } from '../../services/firebaseSetUp';
import logo from '../../assets/giuribot_logo_extractor.png';
import { PlansViewer } from '../Plans/Plans';
import { checkoutApi, updateSubscriptionApi } from '../../api/pricing';
import { toastAtom } from '../../state/toast.atom';
import Footer from '../Footer/Footer';
import { SummaryDocs } from '../SummaryDocs/SummaryDocs';
import { GiustiziaAmministrativa } from '../GiustiziaAmministrativa/GiustiziaAmministrativa';
import { extendedTheme } from '../../themes/extendedTheme';
import AppFooter from '../Landing/views/AppFooter';

const auth = getAuth(app);

export const Panel = () => {
  const [appState, setAppState] = useRecoilState(appAtom);
  const [toastState, setToastState] = useRecoilState(toastAtom);
  const [planOpen, setPlanOpen] = React.useState(false);

  const [planChecked, setPlanChecked] = React.useState(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = ['Esci'];

  useEffect(() => {
    if (appState.user) {
      if (appState.user.plan == null && planChecked === false) {
        setPlanChecked(true);
      }
    }
  }, [appState.user]);

  const primaryColor = extendedTheme.colorSchemes.light?.palette.primary.main;

  return (
    <Box>
      <PlansViewer
        selectPlan={(plan: string) => {
          setAppState((state) => ({
            ...state,
            isLoading: true,
          }));

          if (appState.plan !== null) {
            updateSubscriptionApi(plan)
              .then((response) => {
                window.location.href = response.data.url;
                setAppState((state) => ({
                  ...state,
                  isLoading: false,
                }));

                setToastState({
                  open: true,
                  message: 'Piano aggiornato con successo',
                });
              })
              .catch((error) => {
                console.error(error);
                setAppState((state) => ({
                  ...state,
                  isLoading: false,
                }));

                setToastState({
                  open: true,
                  message: "Errore durante l'aggiornamento del piano",
                });
              });
          } else {
            checkoutApi(plan)
              .then((response) => {
                window.location.href = response.data.url;
                setAppState((state) => ({
                  ...state,
                  isLoading: false,
                }));
              })
              .catch((error) => {
                console.error(error);
                setAppState((state) => ({
                  ...state,
                  isLoading: false,
                }));

                setToastState({
                  open: true,
                  message: 'Errore durante il checkout del piano',
                });
              });
          }
        }}
        open={planOpen}
        onClose={() => {
          setPlanOpen(false);
        }}
      />
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: 'white',
          borderBottom: '1px solid #ccc',
          mb: 0,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant="h6" component="div">
              <Box
                component={'img'}
                src={logo}
                height={{
                  xs: 40,
                  sm: 50,
                }}
              />
            </Typography>
          </Box>

          {true !== true ? (
            <Box
              sx={{
                transform: {
                  xs: 'translateX(0px)',
                  sm: 'translateX(0px)',
                  md: 'translateX(-30px)',
                },
              }}
            >
              <Stack direction={'row'} spacing={0} justifyContent={'center'}>
                <Grid2
                  container
                  sx={{
                    width: {
                      xs: 200,
                      sm: 200,
                      md: 400,
                    },
                  }}
                  spacing={2}
                >
                  <Grid2
                    size={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography
                      color="primary"
                      fontWeight={'bold'}
                      fontSize={{
                        xs: 12,
                        sm: 16,
                      }}
                    >
                      QUOTA
                    </Typography>
                  </Grid2>
                  <Grid2 size={6}>
                    <Box
                      sx={{
                        borderRadius: '4px',
                        p: 1,
                        border: '1px solid #ccc',
                        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',
                      }}
                    >
                      <Typography variant="h6" textAlign={'center'}>
                        <code>{appState?.user?.quota}</code>
                      </Typography>
                    </Box>
                  </Grid2>
                </Grid2>
              </Stack>
            </Box>
          ) : null}

          <Box>
            <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
              <Box
                onClick={() => {
                  setPlanOpen(true);
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: primaryColor,
                  borderRadius: '4px',
                  p: 1,
                  px: 2,

                  //   boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  textAlign={'center'}
                  color="primary"
                  fontSize={{
                    xs: 16,
                    sm: 20,
                  }}
                >
                  {/* {appState?.user?.plan} */}
                  {appState.plan ? appState.plan?.name : 'Scegli un piano'}
                </Typography>
              </Box>
              <Box>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {appState.user ? (
                      <Avatar
                        alt={appState.user?.email}
                        src={
                          appState.user?.picture !== ''
                            ? appState.user?.picture
                            : localStorage.getItem('profilePictureBase64') ||
                              undefined
                        }
                      />
                    ) : null}
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => {
                        handleCloseUserMenu();

                        if (setting === 'Esci') {
                          signOut(auth);
                          setAppState((prevState) => ({
                            ...prevState,
                            isApiKeyValid: false,
                            apiKey: '',
                          }));
                        }
                      }}
                    >
                      <Typography sx={{ textAlign: 'center' }}>
                        {setting}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>

      <Routes>
        <Route path="/" element={<Navigate to={'/panel/home'} />} />
        <Route path="/home" element={<Home />} />
        <Route path="/structured-output" element={<StructuredOutput />} />
        <Route path="/chat-docs" element={<SummaryDocs />} />
        <Route
          path="/giustizia-amministrativa"
          element={<GiustiziaAmministrativa />}
        />
      </Routes>

      <AppFooter />
    </Box>
  );
};
