import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

let language = (navigator.language || navigator.userLanguage).split('-')[0];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'it',
    lng: language,
    resources: {
      /*
      en: {
        translations: require('./locales/en/translations-en.json'),
      },
      es: {
        translations: require('./locales/es/translations-es.json'),
      },
      */
      it: {
        translations: require('./locales/it/translations-it.json'),
      },
    },
    ns: ['translations-it'],
    defaultNS: 'translations',
  });

i18n.languages = ['en', 'es', 'it'];

export default i18n;
