import { Box, BoxProps, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ProvvedimentoData } from '../../models/provvedimento.model';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { extendedTheme } from '../../themes/extendedTheme';
import { useEffect, useState } from 'react';
import { findBestMatchingFragment } from '../../services/text_matching_processor';
import {
  createHighlightedFragment,
  createHighlightedFragmentContext,
  findBestMatchingFragmentComponent,
} from '../../services/text_matching_processor_fragments';

interface ProvvedimentoProps extends BoxProps {
  data: ProvvedimentoData;
  query: string;
}

export const Provvedimento = styled((props: ProvvedimentoProps) => {
  const [provvedimentoText, setProvvedimentoText] = useState('');

  const newQuery = props.query;

  const bestFragment = findBestMatchingFragmentComponent(
    newQuery,
    props.data.text != undefined ? props.data.text : props.data.chunk,
    300
  );
  const highlighted = createHighlightedFragment(bestFragment);

  const bestFragmentContext = findBestMatchingFragmentComponent(
    newQuery,
    props.data.context != undefined ? props.data.context : '',
    300
  );

  const highlightedContext =
    createHighlightedFragmentContext(bestFragmentContext);

  useEffect(() => {
    checkIfTextExist();
  }, []);

  function checkIfTextExist() {
    if (props.data.text != null || props.data.text != undefined) {
      console.log('Props text is not null');
      const textToShow = findBestMatchingFragment(
        props.query,
        props.data.text,
        300
      );

      console.log(`Text to show: ${textToShow}`);

      setProvvedimentoText(textToShow);
    }
  }

  return (
    <Box
      {...props}
      p={2}
      sx={{
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '1px solid rgb(230, 230, 230)',
      }}
    >
      <Box flex={1}>
        <Button
          onClick={() => {
            window.open(props.data.url, '_blank');
          }}
        >
          <Box width={'100%'} display={'flex'}>
            <PrintRoundedIcon
              sx={{
                color: extendedTheme.colorSchemes.light?.palette.primary.main,
              }}
            />

            <Typography
              ml={1}
              color={extendedTheme.colorSchemes.light?.palette.primary.main}
              fontWeight={'600'}
            >{`${props.data.numeroProvv} (${props.data.sede}) html`}</Typography>
          </Box>
        </Button>
        <Box display={'flex'}>
          <Typography my={'auto'}>{`${props.data.title}, `}</Typography>
          {true !== true ? (
            <Button
              variant="text"
              sx={{
                color: extendedTheme.colorSchemes.light?.palette.primary.main,
                fontWeight: 600,
              }}
            >
              Verifica Apello
            </Button>
          ) : null}
        </Box>

        <Box
          mt={2}
          mb={2}
          mx={2}
          p={1}
          sx={{
            backgroundColor: 'white',
            borderRadius: '5px',
            border: '1px solid rgb(230, 230, 230)',
          }}
        >
          {provvedimentoText == '' ? (
            <Box
              mt={0.5}
              sx={{
                backgroundColor: 'rgb(232, 244, 255)',
                padding: '10px',
                borderRadius: '3px',
              }}
            >
              <Box display={'flex'} marginTop={'auto'} marginBottom={'auto'}>
                <WarningAmberIcon
                  sx={{
                    width: '16px',
                    height: '15px',
                    color: 'rgb(130, 130, 130)',
                  }}
                />

                <Typography
                  ml={0.5}
                  color={'rgb(130, 130, 130)'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                >
                  Questo contenuto è generato dall'IA e può contenere errori.
                </Typography>
              </Box>

              <Typography mt={0.5} color={'rgb(58, 58, 58)'} fontSize={12}>
                <b
                  color={extendedTheme.colorSchemes.light?.palette.primary.main}
                >
                  Contesto:{' '}
                </b>
                {
                  <div
                    style={{
                      color: '#333',
                    }}
                  >
                    {highlightedContext.elements}
                  </div>
                }
              </Typography>
            </Box>
          ) : null}

          <Typography mt={1.5} fontSize={14} fontWeight={500}>
            {' '}
            {
              <div
                style={{
                  lineHeight: '1.6',
                  fontSize: '16px',
                  color: '#333',
                }}
              >
                {highlighted.elements}
              </div>
            }
          </Typography>
        </Box>

        <Box display={'flex'}>
          <Typography>Numero ricorso: </Typography>
          <Typography ml={0.5} fontWeight={600}>
            {props.data.numeroRicorso}
          </Typography>
        </Box>
      </Box>

      {/* <Box>
      <Button>
        <AutoFixHighRoundedIcon />

        <Typography ml={1} fontSize={12} fontWeight={600}>
          Analisi AI
        </Typography>
      </Button>
    </Box> */}
    </Box>
  );
})(({ theme }) => ({
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.05)', // Soft shadow
  '&:hover': {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.05)', // Slightly harder shadow on hover for effect
  },
}));
