import { httpRequest } from './base';

export const checkoutApi = (price_id: string) => {
  return httpRequest({
    path: '/pricing/checkout',
    method: 'POST',
    data: {
      price_id,
    },
  });
};

export const updateSubscriptionApi = (price_id: string) => {
  return httpRequest({
    path: '/pricing/subscription',
    method: 'PUT',
    data: {
      price_id,
    },
  });
};
