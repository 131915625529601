import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

import FeaturePresentation01 from '../../../assets/feature_presentation_01.jpg';
import FeaturePresentation02 from '../../../assets/feature_presentation_02.jpg';
import FeaturePresentation03 from '../../../assets/feature_presentation_03.jpg';
import FeaturePresentation04 from '../../../assets/feature_presentation_04.jpg';

import { extendedTheme } from '../../../themes/extendedTheme';

export default function ProductCategories() {
  const { t, i18n } = useTranslation();

  const primaryColor = extendedTheme.colorSchemes.light!.palette.primary.main;

  return (
    <Container
      id="features"
      component="section"
      maxWidth={false}
      sx={{ mt: 8, mb: 4, bgcolor: 'white', pt: 10, pb: 10 }}
    >
      <Typography
        variant="h4"
        align="center"
        component="h2"
        fontWeight={600}
        color={primaryColor}
      >
        {t('features').toUpperCase()}
      </Typography>

      <Container
        sx={{
          mt: 20,
          display: 'flex',

          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },
            }}
          >
            <Box
              border={'1px solid rgb(200, 200, 200)'}
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                bgcolor: 'gray',
                backgroundImage: `url(${FeaturePresentation01})`,
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {t('feature_presentation_01_header')}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {t('feature_presentation_01_title')}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {t('feature_presentation_01_subtitle')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          mt: 25,
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            order={{
              xs: 1,
              sm: 1,
              md: 0,
              lg: 0,
              xl: 0,
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {t('feature_presentation_02_header')}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {t('feature_presentation_02_title')}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {t('feature_presentation_02_subtitle')}
              </Typography>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },
            }}
          >
            <Box
              component="img"
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                bgcolor: 'gray',
                backgroundImage: `url(${FeaturePresentation02})`,
                backgroundPosition: 'left',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          mt: 25,
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },
            }}
          >
            {/* <Paper elevation={20}> */}
            <Box
              border={'1px solid rgb(200, 200, 200)'}
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                //  bgcolor: 'gray',
                backgroundImage: `url(${FeaturePresentation03})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
            {/* </Paper> */}
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {t('feature_presentation_03_header')}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {t('feature_presentation_03_title')}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {t('feature_presentation_03_subtitle')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          mt: 25,
          mb: 10,
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            order={{
              xs: 1,
              sm: 1,
              md: 0,
              lg: 0,
              xl: 0,
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {t('feature_presentation_04_header')}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {t('feature_presentation_04_title')}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {t('feature_presentation_04_subtitle')}
              </Typography>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 5,
                xl: 5,
              },
            }}
          >
            <Box
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                bgcolor: 'gray',
                backgroundImage: `url(${FeaturePresentation04})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
