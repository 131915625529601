import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

import { Check, Circle, KeyboardArrowRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { extendedTheme } from '../../../themes/extendedTheme';

// Define styles using makeStyles hook

interface PricingCardProps {
  title: string;
  price: string;
  label: string;
  features: Array<string>;
  description: string;
  buttonText: string;
  onClick: Function;
  headerColor?: string;
  isFree: boolean;
  href?: string;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  price,
  features,
  description,
  buttonText,
  label,
  headerColor,
  isFree,
  onClick,
  href,
}) => {
  const { t } = useTranslation();

  const primaryColor = extendedTheme.colorSchemes.light!.palette.primary.main;
  return (
    <Card
      elevation={0}
      sx={{
        border: '1px solid rgb(220, 220, 220)',
        borderRadius: '7px',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          height: '100%',
        }}
      >
        <Box bgcolor={headerColor ? headerColor : 'white'}>
          <CardContent>
            <Stack spacing={1} alignItems={'center'}>
              {label !== '' ? (
                <Chip
                  label={label}
                  sx={{
                    backgroundColor: headerColor
                      ? 'white'
                      : 'rgb(240, 240, 240)',
                    border: headerColor ? null : '1px solid rgb(220, 220, 220)',
                    opacity: 0.7,
                  }}
                />
              ) : null}

              <Typography
                fontWeight={'bold'}
                color={headerColor ? 'white' : 'black'}
                fontSize={25}
              >
                {title}
              </Typography>
            </Stack>
          </CardContent>
          <Divider />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <CardContent>
            <List sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
              {features.map((e, i) => (
                <ListItem key={i}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <CheckCircleRoundedIcon
                      fontSize="medium"
                      sx={{
                        fontSize: '0.8rem',
                        color: 'rgb(50, 50, 50)',
                      }}
                    />

                    <Typography
                      color={'rgb(80, 80, 80)'}
                      fontSize={16}
                      sx={{
                        verticalAlign: 'top',
                      }}
                    >
                      {e}
                    </Typography>
                  </Stack>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Box>
        <Box>
          <Divider />
          <CardContent
            sx={{
              height: '80px',
            }}
          >
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography sx={{ mr: 1, ml: 1 }}>
                <Stack direction={'row'} spacing={1} alignItems={'baseline'}>
                  <Typography
                    color={primaryColor}
                    fontSize={'22px'}
                    fontWeight={'bold'}
                  >
                    {price}
                  </Typography>

                  {!isFree ? (
                    <Typography color={primaryColor} fontSize={'1.2rem'}>
                      €
                    </Typography>
                  ) : null}
                </Stack>
                {!isFree ? (
                  <Typography fontSize="12px">{t('perMonth')}</Typography>
                ) : null}
              </Typography>
              <Button
                disableRipple
                variant="contained"
                endIcon={<KeyboardArrowRight />}
                disableElevation={true}
                onClick={() => onClick()}
                sx={{
                  color: 'white',
                  height: '50px',
                  backgroundColor: primaryColor,
                }}
              >
                {buttonText}
              </Button>
            </Stack>
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
};

export default PricingCard;
