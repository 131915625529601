import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { app } from '../services/firebaseSetUp';
import { getAuth } from 'firebase/auth';
import { StructuredOutputSession } from '../models/structured_output.model';

const db = getFirestore(app);
const auth = getAuth(app);

export const saveStructuredOutputSessionApi = (
  session: StructuredOutputSession
) => {
  const userDocRef = doc(db, 'users', auth.currentUser!.uid);
  const subcollectionRef = collection(userDocRef, 'structuredOutput');

  const newId = new Date().getTime().toString();
  const newDoc = doc(subcollectionRef, newId);

  return setDoc(newDoc, {
    created: new Date(),
    ...session,
  });
};

export const deleteStructuredOutputSessionApi = async (
  sessionId: string
): Promise<void> => {
  const userDocRef = doc(db, 'users', auth.currentUser!.uid);
  const subcollectionRef = collection(userDocRef, 'structuredOutput');

  const sessionDocRef = doc(subcollectionRef, sessionId);

  await deleteDoc(sessionDocRef);
};

export const updateStructuredOutputSessionApi = async (
  sessionId: string,
  session: StructuredOutputSession
): Promise<void> => {
  const userDocRef = doc(db, 'users', auth.currentUser!.uid);
  const subcollectionRef = collection(userDocRef, 'structuredOutput');

  const sessionDocRef = doc(subcollectionRef, sessionId);

  await setDoc(sessionDocRef, session);
};

export const loadStructuredOutputSessionApi = async (): Promise<
  (StructuredOutputSession & { id: string })[] | null
> => {
  const userId = auth.currentUser!.uid;

  const mainDocRef = collection(db, 'users', userId, 'structuredOutput');
  const q = query(mainDocRef, orderBy('created', 'desc'), limit(10)); // Limit to 10 for performance

  try {
    const querySnapshot = await getDocs(q);
    //Here we convert the docs in a list of StructuredOutputSession
    const docs = querySnapshot.docs.map(
      (doc) =>
        ({
          id: doc.id,
          ...doc.data(),
        } as StructuredOutputSession & { id: string })
    );

    return docs;
  } catch (error) {
    console.error('Error fetching documents:', error);
    return null;
  }
};
