import { CopyAll, Visibility } from '@mui/icons-material';
import { Box, Button, Icon, Stack } from '@mui/material';
import React from 'react';
import { toastAtom } from '../../state/toast.atom';
import { useRecoilState } from 'recoil';
import Markdown from 'marked-react';

interface ChatBubbleProps {
  content: string;
  isADocument: boolean;
  children?: React.ReactNode;
  role?: 'user' | 'system';
  onVisualize: () => void;
}

export const ChatBubble = ({
  content,
  isADocument,
  children,
  role,
  onVisualize,
}: ChatBubbleProps) => {
  const [toastState, setToastState] = useRecoilState(toastAtom);
  return (
    <Stack direction={role === 'user' ? 'row-reverse' : 'row'}>
      <Box
        sx={{
          backgroundColor: role === 'user' ? '#fffff' : '#f0f0f0',
          borderRadius: '7px',
          border: 'solid 1px #ccc',
          p: 2,
          maxWidth: '80%',
          wordBreak: 'break-word',
        }}
      >
        <Markdown>{content}</Markdown>

        {children}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}
        >
          <Button
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(content);
              setToastState({
                open: true,
                message: 'Copiato negli appunti',
                autoHideDuration: 2000,
              });
            }}
          >
            <CopyAll />
          </Button>
          {isADocument ? (
            <Button size="small" onClick={onVisualize}>
              <Visibility />
            </Button>
          ) : null}
        </Box>
      </Box>
    </Stack>
  );
};
