import React from 'react';

import Markdown from 'marked-react';
import { Box, Modal } from '@mui/material';
import { privacyPolicyMarkdown } from './PrivacyPolicyMarkdown';

export const PrivacyPolicy = () => {
  return (
    <Box p={4}>
      <Markdown>{privacyPolicyMarkdown}</Markdown>
    </Box>
  );
};
