import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Grid2,
  Link,
  MenuItem,
  Select,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { appAtom } from '../../state/app.atom';
import { RecoilState, useRecoilState } from 'recoil';
import { WarningAmber } from '@mui/icons-material';
import {
  checkQuota,
  decreaseApiKeyQuota,
  saveSession,
} from '../../api/key.api';
import { ArrowForward, Delete } from '@mui/icons-material';
import { DropzoneArea } from 'mui-file-dropzone';
import PDFExtractor from '../../services/pdf_extractor/pdf_extractor';
import { ChatPromptTemplate } from '@langchain/core/prompts';
import { LLMChain } from 'langchain/chains';

import { ChatOpenAI } from '@langchain/openai';
import { StructuredOutputParser } from '@langchain/core/output_parsers';
import { set, z } from 'zod';
import { SENTENZE_PROMPT, SYSTEM_PROMPT } from '../../prompts/sentence_prompts';
import {
  DataGridPremium as DataGridPro,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { useNavigate } from 'react-router-dom';
import {
  deleteStructuredOutputSessionApi,
  loadStructuredOutputSessionApi,
  saveStructuredOutputSessionApi,
  updateStructuredOutputSessionApi,
} from '../../api/structuredOutput';
import { toastAtom } from '../../state/toast.atom';
import { StructuredOutputSession } from '../../models/structured_output.model';
import { checkQuotaApi, decreaseQuotaApi } from '../../api/users';

export const StructuredOutput = () => {
  const navigate = useNavigate();
  const [appState, setAppState] = useRecoilState(appAtom);
  const [toastState, setToastState] = useRecoilState(toastAtom);
  const [results, setResults] = useState<any[]>([]);
  const [tabValue, setTabValue] = useState('1');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const [toUpdate, setToUpdate] = useState(false);
  const [files, setFiles] = useState<File[] | null>([]);

  const [documentsType, setDocumentsType] = useState<'text' | 'scanned' | ''>(
    'text'
  );

  const [sessionData, setSessionData] = useState<
    (StructuredOutputSession & { id: string }) | null
  >(null);

  const [lastSession, setLastSession] = useState<
    (StructuredOutputSession & { id: string })[] | null
  >([]);

  const [fieldsTemplate, setFieldsTemplate] = useState<
    {
      field: string;
      value: string;
    }[]
  >([
    {
      field: 'anno',
      value: 'Anno della sentenza',
    },
    {
      field: 'numero',
      value: 'Numero della sentenza',
    },
    {
      field: 'oggetto',
      value: 'Oggetto della sentenza',
    },
    {
      field: 'tipologiaAtto',
      value: "Tipologia dell'atto",
    },
    {
      field: 'soggettoRicorrente',
      value: 'Soggetto ricorrente',
    },
    {
      field: 'soggettoResistente',
      value: 'Soggetto resistente',
    },
    {
      field: 'numeroDiRegistro',
      value: 'Numero di registro',
    },
  ]);

  const testPDFExtractor = async () => {
    setAppState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const SentenceModel = z.object({
      anno: z.string().describe('Anno della sentenza'),
      numero: z.string().describe('Numero della sentenza'),
      oggetto: z.string().describe('Oggetto della sentenza'),
      tipologiaAtto: z.string().describe("Tipologia dell'atto"),
      soggettoRicorrente: z.string().describe('Soggetto ricorrente'),
      soggettoResistente: z.string().describe('Soggetto resistente'),
      numeroDiRegistro: z.string().describe('Numero di registro'),
    });
    const dynamicSchema = fieldsTemplate.reduce((acc, field) => {
      acc[field.field] = z.string().describe(field.value);
      return acc;
    }, {} as Record<string, z.ZodString>);

    const DynamicSentenceModel = z.object(dynamicSchema);
    // Prompts

    // Create the prompt template
    const callSentencePrompt = ChatPromptTemplate.fromMessages([
      { role: 'system', content: SYSTEM_PROMPT },
      {
        role: 'user',
        content: `${SENTENZE_PROMPT} \n Ritorna l'output con la seguente struttura: {output}. \n Produce un JSON valido.`,
      },
    ]);

    // Create the output parser
    const parserSentence =
      StructuredOutputParser.fromZodSchema(DynamicSentenceModel);

    // Combine the prompt, LLM, and parser into a chain
    const llm = new ChatOpenAI({
      model: 'gpt-4o-mini',
      apiKey: process.env['REACT_APP_OPENAI_API_KEY'],
      temperature: 0,
    }); // Use "gpt-4o-mini" if needed

    const chain = callSentencePrompt.pipe(llm).pipe(parserSentence);
    let index = 0;
    const arr = [...JSON.parse(JSON.stringify(results))];

    try {
      for (const file of files!) {
        try {
          const quotaAvailable = await checkQuotaApi('documentsQuota');

          if (quotaAvailable < 1) {
            setToastState({
              open: true,
              message: 'Quota esaurita',
              autoHideDuration: 2000,
            });
            setAppState((prevState) => ({
              ...prevState,
              isLoading: false,
            }));
            break;
          }

          setAppState((prevState: any) => ({
            ...prevState,
            apiKeyData: {
              ...prevState.apiKeyData,
              quota: quotaAvailable,
            },
          }));
        } catch (e) {
          setAppState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));

          setToastState({
            open: true,
            message: 'Qualcosa è andato storto',
            autoHideDuration: 2000,
          });
          break;
        }
        index = index + 1;
        const pdfExtractor = new PDFExtractor(file);
        const text =
          documentsType === 'scanned'
            ? await pdfExtractor.extractTextWithOCR()
            : await pdfExtractor.extractText();
        const result = await chain.invoke({
          sentenza: text,
          output: parserSentence.getFormatInstructions(),
        });

        try {
          decreaseQuotaApi('documentsQuota', 1);
        } catch (e) {
          setToastState({
            open: true,
            message: 'Qualcosa è andato storto',
            autoHideDuration: 2000,
          });
          break;
        }

        setAppState((prevState: any) => ({
          ...prevState,
          apiKeyData: {
            ...prevState.apiKeyData,
            quota: prevState.apiKeyData?.quota
              ? prevState.apiKeyData.quota - 1
              : 0,
          },
        }));

        console.log('Result : ', result);

        arr.push({ id: `${file.name}-${index}`, ...result });
        setResults(JSON.parse(JSON.stringify(arr)));

        console.log('Processed document:', result);
      }
    } catch (e) {
      if (arr.length > 0) {
        let arrResults = [...JSON.parse(JSON.stringify(results))];
        for (let i = 0; i < arr.length; i++) {
          let objKeys = Object.keys(arr[i]);
          objKeys.forEach((key) => {
            if (key.trim() === '') {
              delete arr[i][key];
            }
          });
        }

        try {
          //   await saveSession(apiKey, apiKeyData?.session || '', {
          //     results: arrResults,
          //     fieldsTemplate: fieldsTemplate,
          //   });
        } catch (e) {}
      }

      setAppState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }

    let arrResults = [...JSON.parse(JSON.stringify(results))];
    for (let i = 0; i < arr.length; i++) {
      let objKeys = Object.keys(arr[i]);
      objKeys.forEach((key) => {
        if (key.trim() === '') {
          delete arr[i][key];
        }
      });
    }

    try {
      //   await saveSession(apiKey, apiKeyData?.session || '', {
      //     results: arrResults,
      //     fieldsTemplate: fieldsTemplate,
      //   });
    } catch (e) {}

    setResults(arr);
    setAppState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    //console.log('Extracted text:', text);
  };

  const handleFieldChange = (field: string, index: number) => {
    setFieldsTemplate((prevFields) => {
      let arr = [...prevFields];
      arr[index] = {
        field: field,
        value: arr[index].value,
      };

      return arr;
    });
    setToUpdate(true);
  };

  //This method is called when the user first enters to the screen
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getStructuredOutputSessions = async () => {
    loadStructuredOutputSessionApi().then((sessions) => {
      setLastSession(sessions);
      console.log('Sessions:', sessions);
    });
  };

  //This callback is established for initialization
  useEffect(() => {
    //Restore the scroll to the top
    scrollToTop();
  }, []);

  useEffect(() => {
    if (appState.user !== null) {
      getStructuredOutputSessions();
    }
  }, [appState.user]);

  return (
    <Box
      sx={{
        backgroundColor: '#f9f9f9',
        minHeight: 'calc(100vh - 63.99px)',
      }}
    >
      <Container>
        <Breadcrumbs aria-label="breadcrumb" sx={{ pt: 2 }}>
          <Link
            underline="hover"
            color="inherit"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/panel/home');
            }}
          >
            Home
          </Link>

          <Typography sx={{ color: 'text.primary' }}>
            Dati strutturati
          </Typography>
        </Breadcrumbs>
        {/* <Stack direction={'row'} justifyContent={'space-between'}>
              <Box>
                <Typography variant="h5">Benvenuto!</Typography>
              </Box>

              <Stack direction={'row'} spacing={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    window.location.href = 'mailto:twinsoftai@gmail.com';
                  }}
                >
                  <Support />
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setIsApiKeyValid(false);
                    setApiKey('');
                  }}
                >
                  Logout
                </Button>
              </Stack>
            </Stack> */}

        <Box>
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                textAlign: 'center',
              }}
            >
              <TabList
                onChange={handleTabChange}
                aria-label="tabs example"
                sx={{
                  justifyContent: 'center',
                }}
              >
                <Tab label="Documenti" value="1" />
                <Tab label="Risultati" value="2" />
                <Tab label="Storico" value="3" />
              </TabList>
            </Box>
            <TabPanel
              value="3"
              sx={{
                border: '1px solid #ccc',
                mt: 2,
                borderRadius: '4px',
                backgroundColor: 'white',
              }}
            >
              {lastSession?.length === undefined ? null : lastSession?.length >
                0 ? (
                <Stack spacing={2}>
                  {lastSession?.map((session, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        if (
                          window.confirm(
                            `Vuoi caricare i risultati della sessione (${session.id})?`
                          )
                        ) {
                          setResults(session.results);
                          setFieldsTemplate(session.fieldsTemplate);
                          setAppState((prevState: any) => ({
                            ...prevState,
                            apiKeyData: {
                              ...prevState.apiKeyData,
                              session: session.id,
                            },
                          }));
                          setSessionData(session);
                          setTabValue('2');
                        }
                      }}
                      sx={{
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        padding: '16px',
                        backgroundColor: '#f9f9f9',
                        cursor: 'pointer',
                        position: 'relative',
                      }}
                    >
                      <Typography variant="h6">
                        {new Date(
                          (session?.created as any).toDate() as Date
                        ).toLocaleString('it-IT', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            window.confirm(
                              `Sei sicuro di voler eliminare la sessione (${session.id})?`
                            )
                          ) {
                            // Call the API to delete the session
                            // Assuming deleteStructuredOutputSessionApi is a function that deletes the session
                            deleteStructuredOutputSessionApi(session.id)
                              .then(() => {
                                setLastSession(
                                  (prevSessions) =>
                                    prevSessions?.filter(
                                      (s) => s.id !== session.id
                                    ) || null
                                );
                                setToastState({
                                  open: true,
                                  message: 'Sessione eliminata con successo',
                                  autoHideDuration: 2000,
                                });
                              })
                              .catch((error) => {
                                console.error('Error deleting session:', error);
                                setToastState({
                                  open: true,
                                  message:
                                    "Errore durante l'eliminazione della sessione",
                                  autoHideDuration: 2000,
                                });
                              });
                          }
                        }}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          right: 8,
                          transform: 'translateY(-50%)',
                        }}
                      >
                        <Delete />
                      </Button>
                    </Box>
                  ))}
                </Stack>
              ) : (
                <Typography variant="body1" mt={2}>
                  Nessun risultato da visualizzare
                </Typography>
              )}
            </TabPanel>
            <TabPanel
              value="1"
              sx={{
                p: 0,
              }}
            >
              <Box sx={{ mt: 2, mb: 2 }}>
                <Alert
                  icon={<WarningAmber fontSize="inherit" />}
                  severity="error"
                  sx={{
                    borderColor: 'error.light',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                >
                  Attenzione: se i PDF sono immagini scansionati, il processo
                  potrebbe richiedere molto più tempo.
                </Alert>
              </Box>
              <Box
                sx={{
                  border: '1px solid #ccc',
                  mt: 2,
                  borderRadius: '4px',
                  backgroundColor: 'white',
                  p: 3,
                }}
              >
                {(files as File[])?.length > 0 && fieldsTemplate.length > 0 ? (
                  <Button
                    fullWidth
                    variant="contained"
                    disableElevation
                    sx={{
                      mb: 2,
                    }}
                    onClick={() => {
                      testPDFExtractor();
                      setTabValue('2');
                    }}
                    size="large"
                    endIcon={<ArrowForward />}
                  >
                    Inizia estrazione
                  </Button>
                ) : null}

                <Box sx={{}}>
                  <Box>
                    <Typography variant="body2" mb={1}>
                      Tipo di documento PDF:
                    </Typography>
                    <Select
                      sx={{
                        width: 200,
                      }}
                      value={documentsType}
                      onChange={(e) =>
                        setDocumentsType(
                          e.target.value as 'text' | 'scanned' | ''
                        )
                      }
                    >
                      <MenuItem value="text">Testo</MenuItem>
                      <MenuItem value="scanned">Scansionato</MenuItem>
                    </Select>
                  </Box>

                  {documentsType !== '' ? (
                    <Box mt={2}>
                      <DropzoneArea
                        initialFiles={files as File[]}
                        maxFileSize={100 * 1024 * 1024} // 10 MB in bytes
                        dropzoneText="Qui puoi caricare i documenti PDF"
                        filesLimit={300}
                        acceptedFiles={['application/pdf']}
                        onChange={(files) => {
                          console.log('Files:', files);
                          setFiles((prevFiles) => {
                            const newFiles = files.filter(
                              (file) =>
                                !prevFiles?.some(
                                  (prevFile) => prevFile.name === file.name
                                )
                            );
                            return [...(prevFiles || []), ...newFiles];
                          });
                        }}
                        fileObjects={[]}
                        showPreviews={false}
                        showFileNamesInPreview={false}
                        showPreviewsInDropzone={false}
                        showFileNames={false}
                      />
                    </Box>
                  ) : null}

                  <Stack direction={'row'} mt={2} spacing={2}>
                    {files?.map((file, index) => (
                      <Chip
                        key={index}
                        label={file.name}
                        onDelete={() => {
                          setFiles(
                            (prevFiles) =>
                              prevFiles?.filter((_, i) => i !== index) || null
                          );
                        }}
                      />
                    ))}
                  </Stack>
                </Box>
                <Grid2
                  mt={2}
                  container
                  spacing={2}
                  sx={{
                    boxSizing: 'border-box',
                  }}
                >
                  {/* Left Side: Procedural Input Fields */}
                  <Grid2 size={12}>
                    <Typography mb={2}>Colonne Personalizzate</Typography>
                    {fieldsTemplate.map((field, index) => (
                      <Box
                        key={index}
                        mb={2}
                        display="flex"
                        alignItems="center"
                      >
                        <TextField
                          type={'text'}
                          value={field.field}
                          onChange={(el) =>
                            handleFieldChange(el.currentTarget.value, index)
                          }
                          placeholder="Nome del campo"
                          fullWidth
                        />
                        <TextField
                          sx={{ ml: 1 }}
                          value={field.value}
                          onChange={(el) => {
                            const value = el.target.value;
                            setFieldsTemplate((prevFields) => {
                              let arr = [...prevFields];
                              arr[index] = {
                                field: arr[index].field,
                                value: value,
                              };
                              return arr;
                            });
                            setToUpdate(true);
                          }}
                          fullWidth
                          placeholder="Descrizione del campo"
                        />
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            setFieldsTemplate((prevFields) =>
                              prevFields.filter((_, i) => i !== index)
                            );
                            setToUpdate(true);
                          }}
                          sx={{ ml: 2 }}
                        >
                          <Delete />
                        </Button>
                      </Box>
                    ))}
                    <Button
                      variant="outlined"
                      onClick={() => {
                        const newField = { field: '', value: '' };
                        setFieldsTemplate((prevFields) => [
                          ...prevFields,
                          newField,
                        ]);
                      }}
                    >
                      Aggiungi Colonna
                    </Button>
                  </Grid2>

                  {/* Right Side: JSON Display */}
                  {/* <Grid2 size={6}>
                      <Typography variant="h6" mb={2}>
                        Anteprima Modello
                      </Typography>
                      <Box
                        sx={{
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '16px',
                          backgroundColor: '#f9f9f9',
                          overflow: 'auto',
                        }}
                      >
                        <pre>
                          {JSON.stringify(
                            fieldsTemplate.reduce(
                              (acc: { [key: string]: string }, field) => {
                                acc[field.field] = field.value;
                                return acc;
                              },
                              {}
                            ),
                            null,
                            2
                          )}
                        </pre>
                      </Box>
                    </Grid2> */}
                </Grid2>
                {/* Add content for Tab 1 here */}
              </Box>
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                border: '1px solid #ccc',
                mt: 2,
                borderRadius: '4px',
                backgroundColor: 'white',
              }}
            >
              <Stack direction={'row'} justifyContent={'end'}>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    mb: 1,
                  }}
                  onClick={() => {
                    console.log('Results:', results);
                    console.log('Fields:', fieldsTemplate);
                    setAppState((prevState) => ({
                      ...prevState,
                      isLoading: true,
                    }));

                    let arr = [...JSON.parse(JSON.stringify(results))];
                    for (let i = 0; i < arr.length; i++) {
                      let objKeys = Object.keys(arr[i]);
                      objKeys.forEach((key) => {
                        if (key.trim() === '') {
                          delete arr[i][key];
                        }
                      });
                    }

                    if (sessionData) {
                      updateStructuredOutputSessionApi(sessionData.id, {
                        results: arr,
                        fieldsTemplate: fieldsTemplate,
                      })
                        .then(() => {
                          setAppState((prevState) => ({
                            ...prevState,
                            isLoading: false,
                          }));
                        })
                        .catch((e) => {
                          console.log(e);
                          setAppState((prevState) => ({
                            ...prevState,
                            isLoading: false,
                          }));
                          setToastState({
                            open: true,
                            message: 'Qualcosa è andato storto',
                            autoHideDuration: 2000,
                          });
                        });
                    } else {
                      saveStructuredOutputSessionApi({
                        results: arr,
                        fieldsTemplate: fieldsTemplate,
                      })
                        .then(() => {
                          setAppState((prevState) => ({
                            ...prevState,
                            isLoading: false,
                          }));
                        })
                        .catch((e) => {
                          console.log(e);
                          setAppState((prevState) => ({
                            ...prevState,
                            isLoading: false,
                          }));
                          setToastState({
                            open: true,
                            message: 'Qualcosa è andato storto',
                            autoHideDuration: 2000,
                          });
                        });
                    }
                  }}
                >
                  Salva
                </Button>
              </Stack>
              <DataGridPro
                slots={{
                  toolbar: GridToolbar,
                }}
                columns={fieldsTemplate.map((field) => ({
                  field: field.field,
                  headerName: field.field,
                  width: 150,
                }))}
                rows={results || []}
                autoHeight
              />
              {/* Add content for Tab 2 here */}
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Box>
  );
};
