import {
  addDoc,
  DocumentData,
  DocumentReference,
  getFirestore,
  collection,
  setDoc,
  orderBy,
  limit,
  query,
  getDocs,
} from 'firebase/firestore';
import { app } from '../services/firebaseSetUp';
import { getDoc, doc } from 'firebase/firestore';

const db = getFirestore(app);

export const getGeneralParametersApi = async () => {
  return new Promise<DocumentData>(async (resolve, reject) => {
    try {
      const parametersDocRef = doc(db, 'generalParameters', 'parameters');
      const parametersDoc = await getDoc(parametersDocRef);

      if (parametersDoc.exists()) {
        resolve(parametersDoc.data());
      } else {
        reject(new Error('Document not found'));
      }
    } catch (error) {
      reject(error);
    }
  });
};
