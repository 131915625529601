import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import { extendedTheme } from '../../../themes/extendedTheme';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import InteligentSearch from '../../../assets/smart_search.jpg';
import StructuredData from '../../../assets/structured_data.jpg';
import LegalAssistant from '../../../assets/legal_assistant.jpg';

function WhySection() {
  const { t } = useTranslation();
  return (
    <Box component="section" sx={{ bgcolor: '#f5f5f5' }}>
      <Typography
        variant="h4"
        color={'primary'}
        textAlign={'center'}
        component="h2"
        fontWeight={600}
        pt={10}
      >
        {t('why_giuribot_uppercase')}
      </Typography>

      <Typography
        fontWeight={500}
        // fontSize={{
        //   xs: 20,
        //   sm: 20,
        //   md: 30,
        // }}
        mt={4}
        mx={'auto'}
        pl={{
          xs: 5,
          sm: 10,
          md: 20,
        }}
        pr={{
          xs: 5,
          sm: 10,
          md: 20,
        }}
        maxWidth={'1400px'}
        textAlign={'center'}
        variant="h6"
      >
        {t('section_02_subtitle')}
      </Typography>
      <Box
        component="section"
        sx={{ display: 'flex', overflow: 'hidden' }}
        mx={{
          xs: 3,
        }}
      >
        <Container
          sx={{ mt: 8, mb: 10, display: 'flex', position: 'relative' }}
        >
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Card
                variant="outlined"
                sx={{
                  pt: 0,
                  pb: 10,
                  paddingLeft: '0px',
                  paddingRight: '0px',

                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component={'img'}
                    sx={{
                      backgroundImage: `url(${InteligentSearch})`,
                      width: '100%',
                      height: '150px',
                      backgroundSize: 'cover',
                      borderRadius: '10px 10px 0px 0px',
                    }}
                  ></Box>
                  <Typography
                    paddingLeft={5}
                    paddingRight={5}
                    variant="h5"
                    textAlign={'start'}
                    sx={{ my: 4, mb: 2 }}
                    fontWeight={600}
                  >
                    {t('feature_list_01_title')}
                  </Typography>
                  <Typography
                    paddingLeft={5}
                    paddingRight={5}
                    fontSize={20}
                    textAlign={'center'}
                  >
                    {t('feature_list_01_subtitle')}
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                variant="outlined"
                sx={{
                  pt: 0,
                  pb: 10,
                  paddingLeft: '0px',
                  paddingRight: '0px',

                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component={'img'}
                    sx={{
                      backgroundImage: `url(${StructuredData})`,
                      width: '100%',
                      height: '150px',
                      backgroundSize: 'cover',
                      borderRadius: '10px 10px 0px 0px',
                    }}
                  ></Box>
                  <Typography
                    paddingLeft={5}
                    paddingRight={5}
                    sx={{ my: 4, mb: 2 }}
                    variant="h5"
                    textAlign={'center'}
                    fontWeight={600}
                  >
                    {t('feature_list_02_title')}
                  </Typography>
                  <Typography
                    paddingLeft={5}
                    paddingRight={5}
                    fontSize={20}
                    textAlign={'center'}
                  >
                    {t('feature_list_02_subtitle')}
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                variant="outlined"
                sx={{
                  pt: 0,
                  pb: 10,
                  paddingLeft: '0px',
                  paddingRight: '0px',

                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component={'img'}
                    sx={{
                      backgroundImage: `url(${LegalAssistant})`,
                      width: '100%',
                      height: '150px',
                      backgroundSize: 'cover',
                      borderRadius: '10px 10px 0px 0px',
                    }}
                  ></Box>
                  <Typography
                    paddingLeft={5}
                    paddingRight={5}
                    variant="h5"
                    textAlign={'center'}
                    fontWeight={600}
                    sx={{ my: 4, mb: 2 }}
                  >
                    {t('feature_list_03_title')}
                  </Typography>
                  <Typography
                    paddingLeft={5}
                    paddingRight={5}
                    fontSize={20}
                    textAlign={'center'}
                  >
                    {t('feature_list_03_subtitle')}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default WhySection;
