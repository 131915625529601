import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { BoxProps, SxProps } from '@mui/system';
import './ProductHeroLayout.css';
import { Box } from '@mui/material';

interface ProductHeroLayoutProps {
  sxBackground: SxProps<Theme>;
}

export default function ProductHeroLayout(
  props: ProductHeroLayoutProps & BoxProps
) {
  const { children } = props;

  return (
    <Box component="section" bgcolor="white" className="hero-background-image">
      {children}
    </Box>
  );
}
