import React, { useState, useEffect } from 'react';
import { Input, InputProps, styled } from '@mui/material';
import { extendedTheme } from '../../../themes/extendedTheme';

// Styled component for the animated input border
const AnimatedInput = styled(Input)(({ theme }) => ({
  width: '100%',

  '& .MuiInputBase-input': {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    borderStyle: 'solid',

    borderRadius: 5,
    border: '2px solid rgb(230, 230, 230)',
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    backgroundColor: 'white',
    height: '50px',

    '&::placeholder': {
      color: 'rgba(59, 158, 250, 1.0)', // Change the placeholder color
      fontStyle: 'italic', // Optional: make the text italic
    },
  },
  borderRadius: 5,
}));

const AutoBorderColorChangingInput: React.FC = ({ ...props }: InputProps) => {
  const colors = ['#2395ff', '#aad6ff', '#f4faff']; // Red, Green, Blue
  const [borderColor, setBorderColor] = useState<string>(colors[0]);

  const primaryColor = extendedTheme.colorSchemes.light?.palette.primary.main;

  return (
    <AnimatedInput
      disableUnderline
      {...props}
      sx={{
        color: primaryColor,
        '& .MuiInputBase-input': {
          borderColor: borderColor,
        },
      }}
    />
  );
};

export default AutoBorderColorChangingInput;
