import { Box } from '@mui/material';
import React from 'react';
import { Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

export const SuccessPayment = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CheckCircleIcon style={{ fontSize: 100, color: 'green' }} />
        <Typography variant="h4" gutterBottom>
          Pagamento Riuscito!
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Il tuo pagamento è stato elaborato con successo.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="/"
          disableElevation
          onClick={() => {
            navigate('/');
          }}
        >
          Torna alla Home
        </Button>
      </Box>
    </Box>
  );
};
