import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  LinearProgress,
  LinearProgressProps,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ProvvedimentoData } from '../../models/provvedimento.model';
import { Provvedimento } from '../../components/Provvedimento/Provvedimento';
import { Info, SearchRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { vectorSearchApi } from '../../api/search';
import { checkQuotaApi, decreaseQuotaApi } from '../../api/users';
import { useRecoilState } from 'recoil';
import { toastAtom } from '../../state/toast.atom';
import { getGeneralParametersApi } from '../../api/parameters.api';

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function YearsBar({ years }: { years: any[] }) {
  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'center'}
      mt={2}
      justifyContent={'space-between'}
      sx={{
        paddingRight: '43px',
      }}
    >
      {years.map((year, index) => {
        const fontSize = index % 5 === 0 ? '1rem' : '0.6rem';
        return (
          <Box key={year}>
            <Box
              sx={{
                height: '25px',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                direction: 'column',
              }}
            >
              <Typography fontSize={fontSize}>{year.value}</Typography>
            </Box>
            <Box
              sx={{
                height: '6px',
                marginTop: '0.5px',
                borderRight: '2px solid #ccc',
              }}
            ></Box>
          </Box>
        );
      })}
    </Stack>
  );
}

export const GiustiziaAmministrativa = () => {
  const navigate = useNavigate();
  const [toast, setToast] = useRecoilState(toastAtom);
  const provvedimentiTypes = [
    { value: 'DECRETO', label: 'Decreto' },
    { value: 'ORDINANZA', label: 'Ordinanza' },
    { value: 'PARERE', label: 'Parere' },
    { value: 'SENTENZA', label: 'Sentenza' },
    { value: 'ADUNANZA PLENARIA', label: 'Adunanza Plenaria' },
    { value: 'ADUNANZA GENERALE', label: 'Adunanza Generale' },
  ];

  const sedi = [
    { value: 'CONSIGLIO DI STATO', label: 'Consiglio di Stato' },
    { value: 'CONSIGLIO DI GIUSTIZIA AMMINISTRATIVA', label: 'C.G.A.R.S' },
    { value: 'ANCONA', label: 'Ancona' },
    { value: 'AOSTA', label: 'Aosta' },
    { value: 'BARI', label: 'Bari' },
    { value: 'BOLOGNA', label: 'Bologna' },
    { value: 'BOLZANO', label: 'Bolzano' },
    { value: 'BRESCIA', label: 'Brescia' },
    { value: 'CAGLIARI', label: 'Cagliari' },
    { value: 'CAMPOBASSO', label: 'Campobasso' },
    { value: 'CATANIA', label: 'Catania' },
    { value: 'CATANZARO', label: 'Catanzaro' },
    { value: 'FIRENZE', label: 'Firenze' },
    { value: 'GENOVA', label: 'Genova' },
    { value: "L'AQUILA", label: "L'Aquila" },
    { value: 'LATINA', label: 'Latina' },
    { value: 'LECCE', label: 'Lecce' },
    { value: 'MILANO', label: 'Milano' },
    { value: 'NAPOLI', label: 'Napoli' },
    { value: 'PALERMO', label: 'Palermo' },
    { value: 'PARMA', label: 'Parma' },
    { value: 'PERUGIA', label: 'Perugia' },
    { value: 'PESCARA', label: 'Pescara' },
    { value: 'POTENZA', label: 'Potenza' },
    { value: 'REGGIOCALABRIA', label: 'ReggioCalabria' },
    { value: 'ROMA', label: 'Roma' },
    { value: 'SALERNO', label: 'Salerno' },
    { value: 'TORINO', label: 'Torino' },
    { value: 'TRENTO', label: 'Trento' },
    { value: 'TRIESTE', label: 'Trieste' },
    { value: 'VENEZIA', label: 'Venezia' },
  ];

  const anni = [
    { value: 2024, label: '2024' },
    { value: 2023, label: '2023' },
    { value: 2022, label: '2022' },
    { value: 2021, label: '2021' },
    { value: 2020, label: '2020' },
    { value: 2019, label: '2019' },
    { value: 2018, label: '2018' },
    { value: 2017, label: '2017' },
    { value: 2016, label: '2016' },
    { value: 2015, label: '2015' },
    { value: 2014, label: '2014' },
    { value: 2013, label: '2013' },
    { value: 2012, label: '2012' },
    { value: 2011, label: '2011' },
    { value: 2010, label: '2010' },
    { value: 2009, label: '2009' },
    { value: 2008, label: '2008' },
    { value: 2007, label: '2007' },
    { value: 2006, label: '2006' },
    { value: 2005, label: '2005' },
    { value: 2004, label: '2004' },
    { value: 2003, label: '2003' },
    { value: 2002, label: '2002' },
    { value: 2001, label: '2001' },
    { value: 2000, label: '2000' },
    { value: 1999, label: '1999' },
    { value: 1998, label: '1998' },
    { value: 1997, label: '1997' },
    { value: 1996, label: '1996' },
    { value: 1995, label: '1995' },
    { value: 1994, label: '1994' },
    { value: 1993, label: '1993' },
    { value: 1992, label: '1992' },
    { value: 1991, label: '1991' },
  ];

  const searchTypes = [
    { value: 1, label: 'Ibrido' },
    { value: 2, label: 'Semantico' },
  ];

  const [tipoProvvedimento, setTipoProvvedimento] = useState('');

  const onChangeProvvedimento = (event: SelectChangeEvent<unknown>) => {
    setTipoProvvedimento(event.target.value as string);
  };

  const [sede, setSede] = useState('');

  const onChangeSede = (event: SelectChangeEvent<unknown>) => {
    setSede(event.target.value as string);
  };

  const [year, setYear] = useState('');
  const onChangeYear = (event: SelectChangeEvent<unknown>) => {
    setYear(event.target.value as string);
  };

  const [number, setNumber] = useState('');
  const onChangeNumber = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNumber(event.target.value);
  };

  const [propmt, setPrompt] = useState('');
  const onChangePrompt = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPrompt(event.target.value);
  };

  const [searchType, setSearchType] = useState('');

  const [parameters, setParameters] = useState<{
    availableSentencesPercentage: number;
  } | null>(null);
  const onChangeSearch = (event: SelectChangeEvent<unknown>) => {
    setSearchType(event.target.value as string);
  };

  const [provvedimenti, setProvvedimenti] = useState<ProvvedimentoData[]>([]);

  const [pending, setPending] = useState<boolean>(false);

  const getGeneralParameters = async () => {
    getGeneralParametersApi().then((result) => {
      setParameters(
        result as {
          availableSentencesPercentage: number;
        }
      );
    });
  };

  const performSearch = async () => {
    if (String(propmt).trim().length == 0) {
      console.log('Empty prompt');
      return;
    }

    const quota = await checkQuotaApi('searchQuota');

    if (quota > 0) {
      setPending(true);

      vectorSearchApi({
        query: String(propmt).trim(),
        sede,
        tipo: tipoProvvedimento,
        year: String(year),
        numero: String(number),
      })
        .then((result) => {
          console.log(result.data);

          decreaseQuotaApi('searchQuota', 1);

          setPending(false);

          setProvvedimenti(
            (result.data.results as any[]).map((e, i) => ({
              ...e,
              ...e.metadata,
            }))
          );
        })
        .catch((e) => {
          console.log(e);
          setPending(false);
        });
    } else {
      setToast({
        open: true,
        message: 'Quota esaurita',
        autoHideDuration: 6000,
      });
    }
  };

  useEffect(() => {
    //Restore the scroll to the top
    scrollToTop();

    const params = new URLSearchParams(window.location.search);
    const searchQuery = params.get('search');

    if (searchQuery) {
      setPrompt(searchQuery);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    getGeneralParameters();
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: '#f9f9f9',
        minHeight: 'calc(100vh - 63.99px)',
      }}
    >
      <Container>
        <Breadcrumbs aria-label="breadcrumb" sx={{ pt: 2 }}>
          <Link
            underline="hover"
            color="inherit"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/panel/home');
            }}
          >
            Home
          </Link>

          <Typography sx={{ color: 'text.primary' }}>
            Giustizia Amministrativa
          </Typography>
        </Breadcrumbs>

        <Typography mt={2}>Sentenze disponibili</Typography>
        <YearsBar years={anni.slice(0, 25)} />

        {parameters ? (
          <LinearProgressWithLabel
            value={parameters.availableSentencesPercentage}
          />
        ) : (
          <CircularProgress />
        )}

        <Box sx={{ mt: 2, mb: 2 }}>
          <Alert
            icon={<Info fontSize="inherit" />}
            severity="info"
            sx={{
              borderColor: 'info.light',
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
          >
            Ogni giorno nuove sentenze saranno caricate su Giuribot.
          </Alert>
        </Box>

        <Box
          sx={{
            border: '1px solid #ccc',
            mt: 2,
            borderRadius: '4px',
            backgroundColor: 'white',
            p: 3,
          }}
        >
          <Box width={'100%'}>
            <Stack direction={'row'} spacing={2}>
              <Box flex={1}>
                <FormControl fullWidth>
                  <FormLabel>Tipo Provvedimento</FormLabel>
                  <Select
                    value={tipoProvvedimento}
                    onChange={onChangeProvvedimento}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>Tutti</em>
                    </MenuItem>
                    {provvedimentiTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box flex={1}>
                <FormControl fullWidth>
                  <FormLabel>Sede</FormLabel>
                  <Select
                    value={sede}
                    onChange={onChangeSede}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>Tutti</em>
                    </MenuItem>
                    {sedi.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box flex={1}>
                <FormControl fullWidth>
                  <FormLabel>Anno</FormLabel>
                  <Select
                    value={year}
                    onChange={onChangeYear}
                    fullWidth
                    variant="outlined"
                    placeholder="Anno"
                  >
                    <MenuItem value="">
                      <em>Tutti</em>
                    </MenuItem>
                    {anni.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        disabled={option.value < 2023}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box flex={1} display={'flex'}>
                <FormControl fullWidth>
                  <FormLabel>Numero</FormLabel>
                  <TextField
                    value={number}
                    variant="outlined"
                    fullWidth
                    onChange={onChangeNumber}
                  />
                </FormControl>
              </Box>
            </Stack>
          </Box>

          <Stack direction={'row'} spacing={2} mt={2}>
            <Box flex={5}>
              <TextField
                placeholder="Inserisci il prompt o la parola chiave dei provvedimenti"
                variant="outlined"
                fullWidth
                value={propmt}
                onChange={onChangePrompt}
              />
            </Box>

            <Button
              sx={{
                marginLeft: '10px',
                marginRight: '16px',
                fontWeight: 600,
              }}
              onClick={() => performSearch()}
            >
              <SearchRounded />
              Cerca
            </Button>
          </Stack>
        </Box>

        <Box>
          {pending ? (
            <Stack justifyContent={'center'} mb={1}>
              <CircularProgress />
            </Stack>
          ) : null}
          {provvedimenti.map((e) => (
            <Provvedimento
              data={e}
              sx={{
                marginTop: '10px',
              }}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};
