import { Send, StopCircle } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Container,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatBubble } from '../../components/ChatBubble/ChatBubble';
import { ChatPromptTemplate } from '@langchain/core/prompts';
import { ChatOpenAI } from '@langchain/openai';
import { useRecoilState } from 'recoil';
import { appAtom } from '../../state/app.atom';
import PDFExtractor from '../../services/pdf_extractor/pdf_extractor';
import {
  SUMMARY_SENTENZE_PROMPT,
  SUMMARY_SYSTEM_PROMPT,
  TITOLO_SENTENZA_PROMPT,
} from '../../prompts/summary_prompts';
import { toastAtom } from '../../state/toast.atom';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { SavedChatItem } from './Components/SavedChatItem';
import { ChatHistory } from '../../models/chat_history.model';
import {
  deleteChatFromHistory,
  loadSummaryChatHistory,
  saveFileTemporarily,
  saveSummaryChatHistory,
  updateChatHistory,
} from '../../api/chat.api';
import HistoryPanelContainer from './Components/HistoryPanelContainer';
import { checkQuotaApi, decreaseQuotaApi } from '../../api/users';

export const SummaryDocs = () => {
  const [toastState, setToastState] = useRecoilState(toastAtom);
  const navigate = useNavigate();

  const [toast, setToast] = useRecoilState(toastAtom);
  const [appState, setAppState] = useRecoilState(appAtom);

  const [inputText, setInputText] = useState('');

  const [files, setFiles] = useState<File[] | null>([]);
  const [documentsType, setDocumentsType] = useState<'text' | 'scanned' | ''>(
    'text'
  );

  const [temporaryFileNames, setTemporaryFileNames] = useState<string[]>([]);
  const [currentChat, setCurrentChat] = useState<null | ChatHistory>(null);

  const [chatBotOpen, setChatBotOpen] = useState(false);
  const [summaryGenerated, setSummaryGenerated] = useState(false);

  const [chatId, setChatId] = useState<string | null>(null);

  //This variable is used to refference the point where the scroll must arrive
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  const [blocked, setBlocked] = useState(false);

  const [savedSentenzeText, setSavedSentenzeText] = useState('');
  const [savingChat, setSavingChat] = useState(false);

  const [messages, setMessages] = useState<
    {
      content: string;
      role: 'user' | 'system';
    }[]
  >([]);

  const [savedMessages, setSavedMessages] = useState<ChatHistory[]>([]);

  const [isLoadingHistory, setLoadingHistory] = useState(false);

  const [canSave, setCanSave] = useState(true);

  const [tempUrl, setTempUrl] = useState('');

  //This method is called when the user first enters to the screen
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const saveChat = async () => {
    setSavingChat(true);
    // Create the prompt template
    const callSentencePrompt = ChatPromptTemplate.fromMessages([
      { role: 'system', content: SUMMARY_SYSTEM_PROMPT },
      {
        role: 'user',
        content: `${TITOLO_SENTENZA_PROMPT}`,
      },
    ]);

    // Combine the prompt, LLM, and parser into a chain
    const llm = new ChatOpenAI({
      model: 'gpt-4o-mini',
      apiKey: process.env['REACT_APP_OPENAI_API_KEY'],
      temperature: 0,
    }); // Use "gpt-4o-mini" if needed

    const chain = callSentencePrompt.pipe(llm);

    const result = await chain.stream({
      sentenza: savedSentenzeText,
    });

    let fullMessage = '';

    for await (const chunk of result) {
      // Handle each chunk of data
      fullMessage += chunk.content;
    }

    setSavingChat(false);

    const chatSnapshot = {
      id: '',
      title: fullMessage.replaceAll('"', ''),
      date: Date.now(),
      files: files!.map((e, i) => e.name),
      filesPaths: [],
      filesUrls: [],
      messages: messages,
    };

    await saveSummaryChatHistory(chatSnapshot, files!);

    setCanSave(false);

    setSavedMessages((prevSaved) => {
      const newSaved = [...prevSaved];

      newSaved.unshift(chatSnapshot);

      return newSaved;
    });
  };

  //This method is for update the chat
  const updateChat = async (oldChat: ChatHistory) => {
    setSavingChat(true);
    const chatSnapshot = {
      id: oldChat.id,
      title: oldChat.title,
      date: Date.now(),
      files: files!.map((e, i) => e.name),
      filesPaths: [],
      filesUrls: [],
      messages: messages,
    };
    await updateChatHistory(chatSnapshot);

    await loadChatHistory();
    setSavingChat(false);
  };

  const handleSavedChatOnClick = (data: ChatHistory) => {
    setCurrentChat(data);
    setMessages(data.messages);
    setTemporaryFileNames(data.files);
    setFiles([]);

    setChatBotOpen(true);
    setBlocked(false);
    setSummaryGenerated(true);

    setCanSave(false);
  };

  //This callback is established for initialization
  useEffect(() => {
    //Restore the scroll to the top
    scrollToTop();
    loadChatHistory();
  }, []);

  useEffect(() => {
    //Every time a new message is added the scroll of the chat will go down to the bottom
    scrollToBottom();
    console.log('Messages:', messages);

    if (!blocked) {
      performChatUpdate();
    }

    if (
      summaryGenerated &&
      messages.length > 0 &&
      messages[messages.length - 1].role === 'user'
    ) {
      normalChat();
    }
  }, [messages]);

  const performChatUpdate = async () => {
    if (currentChat != null) {
      await updateChat(currentChat);
    } else {
      setCanSave(true);
    }
  };

  //This method is used to load te chat history from firestore
  const loadChatHistory = async () => {
    setLoadingHistory(true);
    const chatHistoryList = await loadSummaryChatHistory();

    setLoadingHistory(false);

    if (chatHistoryList != null) {
      setSavedMessages(chatHistoryList);
    }
  };

  const deleteChat = async (data: ChatHistory) => {
    await deleteChatFromHistory(data);

    setSavedMessages((prevSaved) => {
      const newSaved = [...prevSaved];

      return newSaved.filter((item) => item.id != data.id);
    });

    setToastState({
      open: true,
      message: 'Chat eliminata',
      autoHideDuration: 6000,
    });
  };

  const normalChat = async () => {
    const callSentencePrompt = ChatPromptTemplate.fromMessages(messages);

    const llm = new ChatOpenAI({
      model: 'gpt-4o-mini',
      apiKey: process.env['REACT_APP_OPENAI_API_KEY'],
      temperature: 0,
    });

    const chain = callSentencePrompt.pipe(llm);

    try {
      const quota = await checkQuotaApi('summaryQuota');

      if (quota > 0) {
        const result = await chain.stream({});

        let index = messages.length;
        let fullMessage = '';

        setBlocked(true);

        await decreaseQuotaApi('summaryQuota', 1);

        for await (const chunk of result) {
          fullMessage += chunk.content;
          // Handle each chunk of data
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages[index] = {
              content: fullMessage,
              role: 'system',
            };
            return newMessages;
          });
        }

        setBlocked(false);
      } else {
        setToast({
          open: true,
          message: 'Non hai abbastanza crediti',
        });
      }
    } catch (e) {
      console.error('Error:', e);

      setToast({
        open: true,
        message: 'Qualcosa è andato storto',
      });
    }

    try {
    } catch (e) {
      console.error('Error:', e);

      setToast({
        open: true,
        message: 'Qualcosa è andato storto',
      });
    }
  };

  const createSummary = async () => {
    // Create the prompt template
    const callSentencePrompt = ChatPromptTemplate.fromMessages([
      { role: 'system', content: SUMMARY_SYSTEM_PROMPT },
      {
        role: 'user',
        content: `${SUMMARY_SENTENZE_PROMPT}`,
      },
    ]);

    // Combine the prompt, LLM, and parser into a chain
    const llm = new ChatOpenAI({
      model: 'gpt-4o-mini',
      apiKey: process.env['REACT_APP_OPENAI_API_KEY'],
      temperature: 0,
    }); // Use "gpt-4o-mini" if needed

    const chain = callSentencePrompt.pipe(llm);

    try {
      for (const file of files!) {
        try {
          const quota = await checkQuotaApi('summaryQuota');

          if (quota > 0) {
            const pdfExtractor = new PDFExtractor(file);
            const text =
              documentsType === 'scanned'
                ? await pdfExtractor.extractTextWithOCR()
                : await pdfExtractor.extractText();
            const result = await chain.stream({
              sentenza: text,
            });

            await decreaseQuotaApi('summaryQuota', 1);

            setSavedSentenzeText(text);

            let fullMessage = '';

            const index = messages.length;

            setBlocked(true);

            for await (const chunk of result) {
              // Handle each chunk of data

              fullMessage += chunk.content;

              setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                newMessages[index] = {
                  content: fullMessage,
                  role: 'system',
                };
                return newMessages;
              });
            }

            setBlocked(false);
            setSummaryGenerated(true);
          } else {
            setToast({
              open: true,
              message: 'Non hai abbastanza crediti',
            });
          }
        } catch (e) {
          setAppState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          setToast({
            open: true,
            message: 'Qualcosa è andato storto',
          });
          break;
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }

    setCanSave(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && summaryGenerated && !blocked) {
      event.preventDefault(); // Prevents the default form submission if wrapped in a form
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (inputText.trim() !== '') {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages.push({
          content: inputText,
          role: 'user',
        });
        return newMessages;
      });
      setInputText('');
    }
  };

  const temporarilySaveDoc = async (files: File[]) => {
    if (files === null || files.length <= 0) {
      console.log('No files to save', files);
      return;
    }

    console.log('Save file temporarily called!');

    const file = files[0];
    const tempUrl = await saveFileTemporarily(file);

    setTempUrl(tempUrl);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f9f9f9',
        minHeight: 'calc(100vh - 63.99px)',
      }}
    >
      <Container>
        <Box display={'flex'} justifyContent={'space-between'} mt={0.5}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ pt: 2 }}>
            <Link
              underline="hover"
              color="inherit"
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/panel/home');
              }}
            >
              Home
            </Link>

            <Typography sx={{ color: 'text.primary' }}>
              Riassunti Documenti
            </Typography>
          </Breadcrumbs>

          {canSave && chatBotOpen ? (
            <Button
              variant="outlined"
              sx={{
                mt: 'auto',
                mb: 'auto',
              }}
              onClick={
                !savingChat
                  ? () => {
                      if (currentChat != null) {
                        updateChat(currentChat!);
                        setCurrentChat(null);
                      } else {
                        saveChat();
                      }
                    }
                  : () => {}
              }
            >
              Salva Chat{' '}
              {!savingChat ? (
                <SaveRoundedIcon
                  sx={{
                    pl: '5px',
                  }}
                />
              ) : (
                <CircularProgress
                  sx={{
                    width: '25px !important',
                    height: '25px !important',
                  }}
                />
              )}
            </Button>
          ) : null}
        </Box>

        <Box
          sx={{
            border: '1px solid #ccc',
            mt: 2,
            borderRadius: '4px',
            backgroundColor: 'white',
            p: 3,
            position: 'relative',
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          {/* {(files as File[])?.length > 0 ? (
            <Button
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mb: 2,
              }}
              onClick={() => {
                //   testPDFExtractor();
                //   setTabValue('2');
              }}
              size="large"
              endIcon={<ArrowForward />}
            >
              Generare riassunto
            </Button>
          ) : null} */}
          {!chatBotOpen ? (
            <Box sx={{}}>
              <Box>
                <Typography variant="body2" mb={1}>
                  Tipo di documento PDF:
                </Typography>
                <Select
                  sx={{
                    width: 200,
                  }}
                  value={documentsType}
                  onChange={(e) =>
                    setDocumentsType(e.target.value as 'text' | 'scanned' | '')
                  }
                >
                  <MenuItem value="text">Testo</MenuItem>
                  <MenuItem value="scanned">Scansionato</MenuItem>
                </Select>
              </Box>

              {documentsType !== '' ? (
                <Box mt={2}>
                  <DropzoneArea
                    initialFiles={files as File[]}
                    maxFileSize={100 * 1024 * 1024} // 10 MB in bytes
                    dropzoneText="Carica il tuo documento PDF"
                    filesLimit={1}
                    acceptedFiles={['application/pdf']}
                    onChange={(files) => {
                      console.log('Files:', files);
                      setFiles((prevFiles) => {
                        const newFiles = files.filter(
                          (file) =>
                            !prevFiles?.some(
                              (prevFile) => prevFile.name === file.name
                            )
                        );
                        return [...(prevFiles || []), ...newFiles];
                      });

                      if (files.length > 0) {
                        temporarilySaveDoc(files);
                        setChatBotOpen(true);
                        setCanSave(false);
                      }
                    }}
                    fileObjects={[]}
                    showPreviews={false}
                    showFileNamesInPreview={false}
                    showPreviewsInDropzone={false}
                    showFileNames={false}
                  />
                </Box>
              ) : null}

              <Box
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 2,
                  border: '1px solid #ccc',
                  mt: 2,

                  p: 2,
                  mr: 0.5,
                }}
              >
                <Typography px={1} mt={2} fontWeight={600} fontSize={12}>
                  Precedenti 30 giorni
                </Typography>

                <Box mt={1} sx={{}}>
                  {!isLoadingHistory
                    ? savedMessages.map((m, i) => (
                        <SavedChatItem
                          data={m}
                          onClick={(data) => {
                            handleSavedChatOnClick(data);
                            setCurrentChat(data);
                          }}
                          onDeleted={deleteChat}
                          isSelected={false}
                        />
                      ))
                    : null}

                  {isLoadingHistory ? (
                    <Box display={'flex'}>
                      <Typography>{'Caricando '}</Typography>
                      <CircularProgress />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
          ) : null}

          {chatBotOpen ? (
            <Box
              display={{
                xs: 'block',
                md: 'block',
                lg: 'flex',
              }}
            >
              <HistoryPanelContainer>
                <Typography px={1} mt={2} fontWeight={600} fontSize={12}>
                  Precedenti 30 giorni
                </Typography>

                <Box
                  mt={1}
                  sx={{
                    overflowY: 'auto',
                  }}
                >
                  {!isLoadingHistory
                    ? savedMessages.map((m, i) => (
                        <SavedChatItem
                          data={m}
                          onClick={(data) => {
                            handleSavedChatOnClick(data);
                            setCurrentChat(data);
                          }}
                          onDeleted={deleteChat}
                          isSelected={
                            currentChat != null ? currentChat.id == m.id : false
                          }
                        />
                      ))
                    : null}

                  {isLoadingHistory ? (
                    <Box display={'flex'}>
                      <Typography>{'Caricando '}</Typography>
                      <CircularProgress />
                    </Box>
                  ) : null}
                </Box>
              </HistoryPanelContainer>

              <Box
                flex={1}
                ref={messagesContainerRef}
                sx={{
                  height: 'calc(100vh - 350px)',
                  backgroundColor: 'white',
                  borderRadius: 2,
                  border: '1px solid #ccc',
                  mt: 2,
                  overflowY: 'auto',
                  p: 2,
                }}
              >
                <Stack spacing={2}>
                  <ChatBubble
                    isADocument={true}
                    content="Vuoi generare un riassunto di questo documento?"
                    onVisualize={() => {
                      if (
                        currentChat != null &&
                        currentChat?.filesUrls != null &&
                        currentChat?.filesUrls.length > 0
                      ) {
                        window.open(currentChat?.filesUrls[0], '_blank');
                      } else {
                        console.log('TempUrl:', tempUrl);
                        if (tempUrl != '') {
                          window.open(tempUrl, '_blank');
                        }
                      }
                    }}
                  >
                    <Stack direction={'row'} mt={2} spacing={2}>
                      {temporaryFileNames.length == 0
                        ? files?.map((file, index) => (
                            <Chip
                              key={index}
                              label={file.name}
                              onDelete={() => {
                                setFiles(
                                  (prevFiles) =>
                                    prevFiles?.filter((_, i) => i !== index) ||
                                    null
                                );

                                setChatBotOpen(false);

                                setSummaryGenerated(false);
                                setMessages([]);
                              }}
                            />
                          ))
                        : temporaryFileNames?.map((file, index) => (
                            <Chip
                              key={index}
                              label={file}
                              onDelete={() => {
                                setFiles(
                                  (prevFiles) =>
                                    prevFiles?.filter((_, i) => i !== index) ||
                                    null
                                );

                                setTemporaryFileNames([]);
                                setMessages([]);

                                setChatBotOpen(false);
                              }}
                            />
                          ))}
                    </Stack>
                  </ChatBubble>

                  {messages.map((message, index) => (
                    <ChatBubble
                      key={index}
                      content={message?.content}
                      isADocument={false}
                      role={message?.role}
                      onVisualize={() => {}}
                    />
                  ))}
                </Stack>
              </Box>
            </Box>
          ) : null}

          {chatBotOpen ? (
            <Box
              sx={{
                marginTop: 2,
                bottom: 16,
              }}
            >
              <TextField
                fullWidth
                placeholder="Chiedi qualcosa riguardo al documento caricato"
                disabled={!summaryGenerated}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: summaryGenerated ? (
                    blocked ? (
                      <StopCircle />
                    ) : (
                      <Button onClick={sendMessage}>
                        <Send />
                      </Button>
                    )
                  ) : blocked ? (
                    <StopCircle />
                  ) : (
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={createSummary}
                    >
                      Riassunto
                    </Button>
                  ),
                }}
              />

              <Typography
                mt={0.4}
                textAlign={'center'}
                fontSize={12}
                color="rgb(120, 120, 120)"
              >
                Giuribot potrebbe commettere errori. Verifica i risultati.
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Container>
    </Box>
  );
};
