import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

function FAQSection() {
  const { t } = useTranslation();
  return (
    <Container
      id="FAQ"
      maxWidth={false}
      component="section"
      sx={{
        mt: 6,
        bgcolor: 'white',
        pt: 10,
        maxWidth: '1450px',
        pl: {
          xs: 5,
          sm: 5,
          md: 5,
          lg: 20,
          xl: 20,
        },
        pr: {
          xs: 5,
          sm: 5,
          md: 5,
          lg: 20,
          xl: 20,
        },
      }}
      disableGutters
    >
      <Typography
        variant="h4"
        align="center"
        component="h2"
        marginBottom={7}
        color={'primary'}
        textAlign={'center'}
        fontWeight={600}
      >
        {t('commonly_asked_questions').toUpperCase()}
      </Typography>

      <Accordion
        defaultExpanded
        sx={{
          mt: 10,
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            borderRadius: 1,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {t('q1')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>{t('a1')}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        sx={{
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {t('q2')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>{t('a2')}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        sx={{
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {t('q3')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>{t('a3')}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        sx={{
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {t('q4')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>{t('a4')}</Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default FAQSection;
